import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as Icons from "react-feather";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
import moment from "moment";
import Swal from "sweetalert2";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.css";
import "animate.css";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import ViewEvents from "./ViewEvents";
import EditEvent from "./EditEvents";
import useClientServices from "../../../services/clientService";
import useEventServices from "../../../services/eventService";
import useRestaurantPackageServices from "../../../services/restaurantPackageService";
import useRestaurantServices from "../../../services/restaurantService";
import { useAuth } from "../../../services/authContext";


const EventsDrawer = ({
  show,
  onClose,
  event,
  create,
  restaurantId,
  isBoarded,
  reservationsMethod,
  adminNotification,
  getPendingEvents,
  startDate,
  adminCreateEvent,
  clientManageEdit = false,
}) => {
  const eventTimeRef = useRef();
  const {
    updateEvent,
    fetchQRCode,
    isSubmitting,
    setSuccess,
    clearIsSubmitting,
    success,
    deleteEvent,
    createEvent,
  } = useEventServices();
  const { user } = useAuth();
  const { getRestaurantPackagesByAdmin, restaurantsPackageData } = useRestaurantPackageServices();
  const { fetchRestaurantInfo, restaurantInfo } = useRestaurantServices();
  const [readingMode, setReadingMode] = useState(true);
  const [eventDetails, setEventDetails] = useState({});
  const [phoneNo, setPhoneNo] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [hrefString, setHrefString] = useState("");
  const [notificationString, setNotificationString] = useState("");
  const [eventId, setEventId] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [restDay, setRestDay] = useState([-1]);
  
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    fetchRestaurantInfo(restaurantId);
  }, [restaurantId]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      if (user.role === "client") {
        fetchClientInfo(decodedToken.userId);
      }
    }
  }, [decodedToken]);

  useEffect(() => {
    const restBusinessHours = restaurantInfo.businessHours;
    if (restBusinessHours && restBusinessHours.length > 0) {
      // Set restaurant restDays
      const restBusinessDays = restBusinessHours
        .filter(businessHour => businessHour.isRestDay === 1)
        .map(businessHour => businessHour.day);
      setRestDay(restBusinessDays.length > 0 ? restBusinessDays : [-1]);
    } else {
      setRestDay([-1]);
    }
  }, [restaurantInfo]);

  useEffect(() => {
    setReadingMode(true);
  }, [show]);

  useEffect(() => {
    if (clientManageEdit) {
      setReadingMode(false);
    }
  }, [clientManageEdit]);

  useEffect(() => {
    const fetchData = async () => {
      if (event) {
        if (event._id) {
          const qrCode = await fetchQRCode(event._id);
          setEventId(event._id);
          setPhoneNo(event.phoneNo);
          setEventDetails({
            id: event._id,
            title: event.title,
            eventName: event.eventName,
            adminId: event.adminId,
            eventDate: moment(getAdjustedDate(event.eventDate)).format(),
            showDate: event.eventDate,
            showTime: event.eventTime,
            eventTime: new Date(`01/01/1970 ${event.eventTime}`),
            closingBefore: event.closingBefore,
            noOfPax: event.noOfPax,
            noOfTable: event.noOfTable,
            status: event.status,
            packages: event.packages,
            phoneNo: event.phoneNo,
            remark: event.remark,
            qrCode: qrCode,
            restaurantContact: event.restaurantContact,
            timestamp : event.timestamp,
            reservationMethod: event.reservationMethod,
          });
        } else if (event.id) {
          const qrCode = await fetchQRCode(event.id);
          setPhoneNo(event._def.extendedProps.phoneNo);
          setEventId(event.id);
          setEventDetails({
            id: event.id,
            title: event.title,
            eventName: event._def.extendedProps.eventName,
            adminId: event._def.extendedProps.adminId,
            showDate: moment(getAdjustedDate(event.start)).format("DD MMMM YYYY"),
            showTime: event._def.extendedProps.time,
            eventDate: moment(event.start).format(),
            status: event._def.extendedProps.status,
            eventTime: new Date(event._def.extendedProps.eventTime),
            closingBefore: event._def.extendedProps.closingBefore,
            noOfPax: event._def.extendedProps.noOfPax,
            noOfTable: event._def.extendedProps.noOfTable,
            packages: event._def.extendedProps.packages,
            phoneNo: event._def.extendedProps.phoneNo,
            remark: event._def.extendedProps.remark,
            qrCode: qrCode,
            timestamp: event._def.extendedProps.timestamp,
            reservationMethod: event._def.extendedProps.reservationMethod,
          });
        }
      } 
    };

    fetchData();
  }, [event]);

  useEffect(() => {
    if (eventDetails.packages) {
      setSelectedPackages(eventDetails.packages);
    }
  }, [eventDetails.packages]);

  useEffect(() => {
    setEventDetails({
      ...eventDetails,
      packages: selectedPackages,
    });
  }, [selectedPackages]);

  useEffect(() => {
    if (user.role === "admin") {
      getRestaurantPackagesByAdmin();
    } else if (restaurantId) {
      getRestaurantPackagesByAdmin(restaurantId);
    }
  }, [event, restaurantId]);

  useEffect(() => {
    if (clientInfo) {
      setClientName(clientInfo.clientName);

      setEventDetails({
        ...eventDetails,
        title: clientInfo.clientName,
      });
    }
  }, [clientInfo]);

  useEffect(() => {
    if (adminNotification) {
      getPendingEvents();
    }

    clearIsSubmitting();

    if ((create || adminCreateEvent) && success) {
      setEventDetails({
        ...eventDetails,
        eventDate: getAdjustedDate(new Date()),
        eventTime: new Date('1970-01-01T12:00:00'),
      });
    }
    if (success) {
      setTimeout(() => {
        setIsDrawerOpen(false);
        closeDrawer();
      }, 3000); // Wait for 3 seconds
      setSuccess(false);
    }
    setIsAccepted(false);
  }, [success]);

  useEffect(() => {
    if (create && user.role === "client") {
      setEventDetails({
        ...eventDetails,
        eventDate: getAdjustedDate(new Date()),
        eventTime: new Date('1970-01-01T12:00:00'),
      });
    }
  }, [create]);

  useEffect(() => {
    if (user.role === "admin" && adminCreateEvent && startDate) {
      setEventDetails({
        ...eventDetails,
        eventDate: getAdjustedDate(startDate),
        eventTime: new Date('1970-01-01T12:00:00'),
      });
    }
  }, [startDate]);

  const closeDrawer = () => {
    setEventDetails({});
    setIsAccepted(false);

    onClose();
  };

  const getAdjustedDate = (date) => {
    let adjustedDate = moment(date);
    while (restDay.includes(adjustedDate.day())) {
      adjustedDate = adjustedDate.add(1, 'days');
    }
    return adjustedDate.toDate();
  };

  const handleInputChange = useCallback((e) => {
    setEventDetails({
      ...eventDetails,
      [e.target.name]: e.target.value,
    });
  }, [eventDetails]);

  const handlePhoneNoChange = useCallback((e) => {
    const inputPhoneNo = e.target.value;
    setPhoneNo(inputPhoneNo);

    // Remove spaces and dashes before sending to the server
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    handleInputChange({
      target: {
        name: e.target.name,
        value: cleanedPhoneNo,
      },
    });
  }, [handleInputChange]);

  const isValidReservationTime = (selectedDate) => {
    if (restaurantInfo.businessHours && restaurantInfo.businessHours.length > 0) {
      const eventDate = moment(eventDetails.eventDate);
      const isToday = eventDate.isSame(moment(), "day");
      const selectedDay = moment(getAdjustedDate(eventDetails.eventDate)).day();
      const selectedTime = moment(selectedDate).format("HH:mm");
    
      // Business hours for the selected day
      const openingHour = moment(restaurantInfo.businessHours[selectedDay]?.startTime)
        .utcOffset(8)
        .format("HH:mm");
      const closingHour = moment(restaurantInfo.businessHours[selectedDay]?.endTime)
        .utcOffset(8)
        .format("HH:mm");
    
      // Secondary business hours if set
      let openingHour2, closingHour2;
      const hasSecondaryTime = restaurantInfo.businessHours[selectedDay]?.setTime2;
      if (hasSecondaryTime) {
        openingHour2 = moment(restaurantInfo.businessHours[selectedDay]?.startTime2)
          .utcOffset(8)
          .format("HH:mm");
        closingHour2 = moment(restaurantInfo.businessHours[selectedDay]?.endTime2)
          .utcOffset(8)
          .format("HH:mm");
      }
    
      // Check if selected time is within the business hours
      const isWithinPrimaryHours =
        selectedTime >= openingHour && selectedTime < closingHour;
      const isWithinSecondaryHours =
        hasSecondaryTime &&
        selectedTime >= openingHour2 &&
        selectedTime < closingHour2;

      // Calculate buffer time: if today, add 30 minutes buffer; otherwise, use selected time
      const bufferTime = isToday
        ? moment().add(30, "minutes").format("HH:mm")
        : openingHour;
    
      // Ensure the selected time is within buffer time if today
      const isAfterBufferTime = selectedTime >= bufferTime;
    
      // Determine if the time is valid
      const isWithinBusinessHours = isWithinPrimaryHours || isWithinSecondaryHours;
      const isValidTime = isWithinBusinessHours && (!isToday || isAfterBufferTime);

      return isValidTime;
    } else {
      return true;
    }
  }

  const handleDateChange = (event, selectedDate) => {
    if (event.target.name === "eventTime") {
      if (!isValidReservationTime(selectedDate)) {
        alert('Reservations can only be made between opening period and must be at least 30 minutes in advance. Thank you.');
        return;
      }
    }

    handleInputChange({
      target: {
        name: event.target.name,
        value: new Date(selectedDate),
      },
    });
  };

  const handleSubmit = async (e, isSave) => {
    e.preventDefault();
    setIsDrawerOpen(true); 

    if (hrefString && !isSave) {
      window.location.href = hrefString;
    }

    if (create || adminCreateEvent) {
      if (user.role === "client") {
        eventDetails.restaurantId = restaurantId;
        eventDetails.title = eventDetails.title || clientName;
      }
      eventDetails.phoneNo = eventDetails.phoneNo || user.clientContact || "";
      eventDetails.timestamp = new Date();
      eventDetails.reservationMethod = user.role === "client" ? reservationsMethod || " " : "manual";
      createEvent(eventDetails, notificationString);
    } else {
      updateEvent(eventDetails, notificationString);
    }
    setIsDrawerOpen(false);
  };

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
  };

  /*Event Delete*/
  const DeleteEvent = () => {
    onClose();
    Swal.fire({
      html: '<div class="mb-3"><i class="ri-delete-bin-6-line fs-5 text-danger"></i></div><h5 class="text-danger">Delete Event ?</h5><p>Deleting a event will permanently remove from your list.</p>',
      customClass: {
        confirmButton: "btn btn-outline-secondary text-danger",
        cancelButton: "btn btn-outline-secondary text-grey",
        container: "swal2-has-bg",
      },
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, Delete Event",
      cancelButtonText: "No, Keep Event",
      reverseButtons: true,
      showDenyButton: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      // cancelButtonId: "delete-cancel-button",
    }).then(async (result) => {
      if (result.value) {
        await deleteEvent(eventDetails.id);

        // event.remove();
        Swal.fire({
          html: '<div class="d-flex align-items-center"><i class="ri-delete-bin-5-fill me-2 fs-3 text-danger"></i><h5 class="text-danger mb-0">Event has been deleted!</h5></div>',
          timer: 2000,
          customClass: {
            content: "p-0 text-left",
            actions: "justify-content-start",
          },
          showConfirmButton: false,
          buttonsStyling: false,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    });
    document.querySelector(".swal2-confirm").id = "delete-confirm-button";
    document.querySelector(".swal2-cancel").id = "delete-cancel-button";
  };

  let packageDepositAmount = "";
  if (Array.isArray(restaurantsPackageData)) {
    const selectedPackage = restaurantsPackageData.find(item => item._id === eventDetails.packages);
    if (selectedPackage) {
      packageDepositAmount = selectedPackage.depositAmount;
    }
  }

  useEffect(() => {
    if (user.role === "client") {
      if (create) {
        // client - create - notBoarded - default whatsapp messaging
        if ((!restaurantInfo.isBoarded || !isBoarded || isBoarded === null) && (restaurantInfo.reservationsMethod === "whatsApp" || reservationsMethod === "whatsApp")) {
          const hrefString = (
            `https://wa.me/+60${
              restaurantInfo?.restaurantContact
            }?text=Hi${
              eventDetails.title ? `, I am ${eventDetails.title}` : ""
            }.%0A%0AI would like to make a reservation on:%0A%0ADate: ${moment(
              eventDetails.eventDate
            ).format("D MMMM YYYY")}%0ATime: ${moment(
              eventDetails.eventTime
            ).format("hh:mm A")}%0ANo. of pax: ${
              eventDetails.noOfPax ? eventDetails.noOfPax : " "
            }%0ANo. of table: ${
              eventDetails.noOfTable ? eventDetails.noOfTable : ""
            }%0APackages Selected: ${
              eventDetails.packages && Array.isArray(eventDetails.packages)
                ? `${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : "No package selected"}
            }%0ARemark: ${
              eventDetails.remark ? eventDetails.remark : ""
            }%0A%0APlease let me know if you can Konfem my reservation.%0AThank you.`
          );
          setHrefString(hrefString);
          // console.log(hrefString); 
        } else if (restaurantInfo.isBoarded || isBoarded) {
          // client - create - boarded - konfemApp messaging
          if (restaurantInfo.reservationsMethod === "konfemApp") {
            const notiString = (
              `${eventDetails.title ? `[${eventDetails.title}]` : ""} ${moment(eventDetails.eventDate).format("D MMMM YYYY")} - ${moment(eventDetails.eventTime).format("hh:mm A")} - ${eventDetails.noOfPax ? eventDetails.noOfPax : " "} Pax\n
              Title: ${
                eventDetails.title
              // }
              // Event Name: ${
              //   eventDetails.eventName
              }
              Date: ${moment(
                  eventDetails.eventDate
                ).format("D MMMM YYYY")}
              Time: ${moment(
                  eventDetails.eventTime
                ).format("hh:mm A")}
              No. of pax: ${
                eventDetails.noOfPax ? eventDetails.noOfPax : ""}
              No. of table: ${
                eventDetails.noOfTable ? eventDetails.noOfTable : ""}
              Packages Selected: ${
                eventDetails.packages && Array.isArray(eventDetails.packages) 
                ? `${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : "No package selected"}
              Remark: ${
                eventDetails.remark ? eventDetails.remark : ""}`
            );
            setNotificationString(notiString);
            // console.log(notiString); 
          // client - create - boarded - whatsApp messaging
          } else if (restaurantInfo.reservationsMethod === "whatsApp") {
            const hrefString = (
              `https://wa.me/+60${
                restaurantInfo?.restaurantContact
              }?text=Hi${
                eventDetails.title ? `, I am ${eventDetails.title}` : ""
              }.%0A%0AI would like to make a reservation on:%0A%0ADate: ${moment(
                eventDetails.eventDate
              ).format("D MMMM YYYY")}%0ATime: ${moment(
                eventDetails.eventTime
              ).format("hh:mm A")}%0ANo. of pax: ${
                eventDetails.noOfPax ? eventDetails.noOfPax : " "
              }%0ANo. of table: ${
                eventDetails.noOfTable ? eventDetails.noOfTable : ""
              }%0APackages Selected: ${
                eventDetails.packages && Array.isArray(eventDetails.packages) 
                ? `${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : "No package selected"}
              }%0ARemark: ${
                eventDetails.remark ? eventDetails.remark : ""
              }%0A%0APlease let me know if you can Konfem my reservation.%0AThank you.`
            );
            setHrefString(hrefString);
            // console.log(hrefString); 
          } else {
            setHrefString("");
          }
        }
      } else if (!create) {
        // client - edit - boarded - KonfemApp messaging - status Pending
        if ((restaurantInfo.isBoarded || isBoarded) && restaurantInfo.reservationsMethod === "konfemApp" && eventDetails.status && eventDetails.status === "Pending") {
          const notiString = (
            `${eventDetails.title ? `[${eventDetails.title}]` : ""} ${moment(event.eventDate).format("D MMMM YYYY")} - ${moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")} - ${event.noOfPax || 0} Pax\n
            Title: ${
              eventDetails.title !== event.title
                ? `${event.title} -> ${eventDetails.title}`
                : `${event.title}`
            }
            Date: ${
              moment(eventDetails.eventDate).format("D MMMM YYYY") !== moment(event.eventDate).format("D MMMM YYYY")
                ? `${moment(event.eventDate).format("D MMMM YYYY")} -> ${moment(eventDetails.eventDate).format("D MMMM YYYY")}`
                : `${moment(event.eventDate).format("D MMMM YYYY")}`
            }
            Time: ${
              moment(eventDetails.eventTime).format("hh:mm A") !== moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")
                ? `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")} -> ${moment(eventDetails.eventTime).format("hh:mm A")}`
                : `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")}`
            }
            No. of pax: ${
              eventDetails.noOfPax !== event.noOfPax
                ? `${event.noOfPax || 0} -> ${eventDetails.noOfPax || 0}`
                : `${event.noOfPax || 0}`
            }
            No. of tables: ${
              eventDetails.noOfTable !== event.noOfTable
                ? `${event.noOfTable || 0} -> ${eventDetails.noOfTable || 0}`
                : `${event.noOfTable || 0}`
            }
            Package: ${
              eventDetails.packages && Array.isArray(eventDetails.packages) && Array.isArray(event.packages)
                ? eventDetails.packages.some(pkg => pkg.packageId !== event.packageId)
                  ? `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"} -> ${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                  : `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : "No package selected"
            }
            Remark: ${
              eventDetails.remark !== event.remark
                ? `${event.remark || "No remark"} -> ${eventDetails.remark || "No remark"}`
                : `${eventDetails.remark || "No remark"}`
            }\n
            Please let me know if you can change my reservation.
            Thank you.`
          );
          setNotificationString(notiString);
          // console.log(notiString); 
        } else if ((restaurantInfo.reservationsMethod === "whatsApp" && eventDetails.status) || (reservationsMethod === "konfemApp" && eventDetails.status && eventDetails.status === "Konfemed")) {
          const hrefString = (
            `https://wa.me/+60${
            restaurantInfo?.restaurantContact
            }?text=Hi${
              eventDetails.title ? `, I am ${eventDetails.title}` : ""
            }.%0A%0AI would like to make a change on my reservation:%0A%0ABooking Name: ${
              eventDetails.title !== event.title
                ? `${event.title} -> ${eventDetails.title}`
                : `${event.title}`
            // }%0AEvent Name: ${
            //   eventDetails.eventName !== event.eventName
            //     ? `${event.eventName} -> ${eventDetails.eventName}`
            //     : `${event.eventName}`
            }%0ADate: ${
              moment(eventDetails.eventDate).format("D MMMM YYYY") !==
              moment(event.eventDate).format("D MMMM YYYY")
                ? `${moment(event.eventDate).format("D MMMM YYYY")} -> ${moment(
                  eventDetails.eventDate
                ).format("D MMMM YYYY")}`
                : `${moment(event.eventDate).format("D MMMM YYYY")}`
            } %0ATime: ${
              moment(eventDetails.eventTime).format("hh:mm A") !==
              moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")
                ? `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format(
                  "hh:mm A"
                )} -> ${moment(eventDetails.eventTime).format("hh:mm A")}`
                : `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format(
                  "hh:mm A"
                )}`
            }%0ANo. of pax: ${
              eventDetails.noOfPax !== event.noOfPax
                ? `${event.noOfPax ? event.noOfPax : 0} -> ${
                  eventDetails.noOfPax ? eventDetails.noOfPax : 0
                } `
                : `${event.noOfPax ? event.noOfPax : 0}`
            }%0ANo. of table: ${
              eventDetails.noOfTable !== event.noOfTable
                ? `${event.noOfTable ? event.noOfTable : 0} -> ${
                  eventDetails.noOfTable ? eventDetails.noOfTable : 0
                }`
                : event.noOfTable
                  ? event.noOfTable
                  : 0
            }%0APackage: ${
              eventDetails.packages && Array.isArray(eventDetails.packages) && Array.isArray(event.packages)
                ? eventDetails.packages.some(pkg => pkg.packageId !== event.packageId)
                  ? `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"} -> ${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                  : `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : "No package selected"
            }%0ARemark: ${
              eventDetails.remark !== event.remark
                ? `${event.remark ? event.remark : "No remark"} -> ${
                  eventDetails.remark ? eventDetails.remark : "No remark"
                }`
                : eventDetails.remark
                  ? eventDetails.remark
                  : "No remark"
            }%0A%0APlease let me know if you can change my reservation.%0AThank you.`
          );
          setHrefString(hrefString);
          // console.log(hrefString); 
        } else {
          setHrefString("");
        }
      } 
    } else if (user.role === "admin" && eventDetails.status) {
      if (eventDetails.status === "Konfemed") {
        if (eventDetails.title !== event.title || moment(eventDetails.eventDate).format("D MMMM YYYY") !== moment(event.eventDate).format("D MMMM YYYY") || moment(eventDetails.eventTime).format("hh:mm A") !== moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A") || eventDetails.noOfPax !== event.noOfPax || eventDetails.noOfTable !== event.noOfTable || eventDetails.package !== event.packageId || eventDetails.remark !== event.remark) {
          setNotificationString(`Changed: ${restaurantInfo?.restaurantName} - ${eventDetails.showDate}\n
          Title: ${
            eventDetails.title !== event.title
              ? `${event.title} -> ${eventDetails.title}`
              : `${event.title}`
          // }
          // Event Name: ${
          //   eventDetails.eventName !== event._def.extendedProps.eventName
          //     ? `${event._def.extendedProps.eventName} -> ${eventDetails.eventName}`
          //     : `${event._def.extendedProps.eventName}`
          }
          Date: ${
            moment(eventDetails.eventDate).format("D MMMM YYYY") !== moment(event.eventDate).format("D MMMM YYYY")
              ? `${moment(event.eventDate).format("D MMMM YYYY")} -> ${moment(eventDetails.eventDate).format("D MMMM YYYY")}`
              : `${moment(event.eventDate).format("D MMMM YYYY")}`
          }
          Time: ${
            moment(eventDetails.eventTime).format("hh:mm A") !==
            moment(new Date(`01/01/1970 ${event._def.extendedProps.time}`)).format("hh:mm A")
              ? `${moment(new Date(`01/01/1970 ${event._def.extendedProps.time}`)).format(
                "hh:mm A"
              )} -> ${moment(eventDetails.eventTime).format("hh:mm A")}`
              : `${moment(new Date(`01/01/1970 ${event._def.extendedProps.time}`)).format(
                "hh:mm A"
              )}`
          }
          No. of pax: ${
            eventDetails.noOfPax !== event.noOfPax
              ? `${event.noOfPax || 0} -> ${eventDetails.noOfPax || 0}`
              : `${event.noOfPax || 0}`
          }
          No. of tables: ${
            eventDetails.noOfTable !== event.noOfTable
              ? `${event.noOfTable || 0} -> ${eventDetails.noOfTable || 0}`
              : `${event.noOfTable || 0}`
          }
          Package: ${
            eventDetails.packages && Array.isArray(eventDetails.packages) && Array.isArray(event.packages)
              ? eventDetails.packages.some(pkg => pkg.packageId !== event.packageId)
                ? `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"} -> ${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
                : `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
              : "No package selected"
          }
          Remark: ${
            eventDetails.remark !== event.remark
              ? `${event.remark || "No remark"} -> ${eventDetails.remark || "No remark"}`
              : `${eventDetails.remark || "No remark"}`
          }`)
        } else {
          setNotificationString(`Konfem: ${restaurantInfo?.restaurantName} - ${eventDetails.showDate}
          Status: Pending -> Konfemed`);
        }
      } else if (eventDetails.status === "Rejected") {
        setHrefString(
          `https://wa.me/+60${eventDetails?.phoneNo}?text=Hi there, thanks for making the reservation at ${restaurantInfo?.restaurantName}. Regarding to your booking, we are currently unavailable at the date and time (${eventDetails.showDate}, ${eventDetails?.showTime}). We would like to propose a new date and time as follows:%0A%0ADate: %0ATime: %0ANo. of Table: %0ANo. of Pax: %0A%0APlease do us a favor to make the reservation again with the new date and time. Sorry for the inconvenience.`);
        setNotificationString(`Reject: ${restaurantInfo?.restaurantName} - ${eventDetails.showDate}
          Status: Pending -> Rejected`);
      } else if (eventDetails.title !== event.title || moment(eventDetails.eventDate).format("D MMMM YYYY") !== moment(event.eventDate).format("D MMMM YYYY") || moment(eventDetails.eventTime).format("hh:mm A") !== moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A") || eventDetails.noOfPax !== event.noOfPax || eventDetails.noOfTable !== event.noOfTable || eventDetails.package !== event.packageId || eventDetails.remark !== event.remark) {
        setNotificationString(`Changed: ${restaurantInfo?.restaurantName} - ${eventDetails.showDate}\n
        Title: ${
          eventDetails.title !== event.title
            ? `${event.title} -> ${eventDetails.title}`
            : `${event.title}`
        // }
        // Event Name: ${
        //   eventDetails.eventName !== event.eventName
        //     ? `${event.eventName} -> ${eventDetails.eventName}`
        //     : `${event.eventName}`
        }
        Date: ${
          moment(eventDetails.eventDate).format("D MMMM YYYY") !== moment(event.eventDate).format("D MMMM YYYY")
            ? `${moment(event.eventDate).format("D MMMM YYYY")} -> ${moment(eventDetails.eventDate).format("D MMMM YYYY")}`
            : `${moment(event.eventDate).format("D MMMM YYYY")}`
        }
        Time: ${
          moment(eventDetails.eventTime).format("hh:mm A") !==
          moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")
            ? `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format(
              "hh:mm A"
            )} -> ${moment(eventDetails.eventTime).format("hh:mm A")}`
            : `${moment(new Date(`01/01/1970 ${event.eventTime}`)).format(
              "hh:mm A"
            )}`
        }
        No. of pax: ${
          eventDetails.noOfPax !== event.noOfPax
            ? `${event.noOfPax || 0} -> ${eventDetails.noOfPax || 0}`
            : `${event.noOfPax || 0}`
        }
        No. of tables: ${
          eventDetails.noOfTable !== event.noOfTable
            ? `${event.noOfTable || 0} -> ${eventDetails.noOfTable || 0}`
            : `${event.noOfTable || 0}`
        }
        Package: ${
          eventDetails.packages && Array.isArray(eventDetails.packages) && Array.isArray(event.packages)
            ? eventDetails.packages.some(pkg => pkg.packageId !== event.packageId)
              ? `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"} -> ${eventDetails.packages.length > 0 ? eventDetails.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
              : `${event.packages.length > 0 ? event.packages.map(pkg => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ") : "No package selected"}`
            : "No package selected"
        }
        Remark: ${
          eventDetails.remark !== event.remark
            ? `${event.remark || "No remark"} -> ${eventDetails.remark || "No remark"}`
            : `${eventDetails.remark || "No remark"}`
        }`)
      }
    } else {
      setHrefString("");
    }
  }, [create, isBoarded, reservationsMethod, eventDetails, user.role, restaurantInfo, clientName]);

  return (
    <>
      <Form id="edit-events-drawer" style={{ position: "relative" }}>
        <div className={classNames("hk-drawer calendar-drawer drawer-right font-monospace h-95", { "drawer-toggle": show })}>
          <div>
            <div className="drawer-header" style={user.role === "client" ? { marginTop: "60px" } : null}>
              <div className="drawer-header-action">
                {!create ? (
                  <>
                    {user.role === "admin" || (user.role === "client" && eventDetails.status !== "Rejected" && new Date() < new Date(eventDetails?.showDate).setDate(new Date(eventDetails?.showDate).getDate() + 1)) ? (
                      <Button
                        size="md"
                        variant="flush-secondary"
                        id="edit_event"
                        className="btn-icon btn-rounded flush-soft-hover me-1"
                        onClick={() => setReadingMode(!readingMode)}
                      >
                        <span className="icon">
                          <span className="feather-icon">
                            {readingMode ? (
                              <Icons.Edit2 />
                            ) : (
                              <Icons.ArrowLeft />
                            )}
                          </span>
                        </span>
                      </Button>
                    ) : null}
                    {user.role === "admin" || (user.role === "client" && eventDetails.status !== "Konfemed") ? (
                      <Button
                        size="md"
                        variant="flush-secondary"
                        id="del_event"
                        className="btn-icon btn-rounded flush-soft-hover me-3"
                        onClick={DeleteEvent}
                      >
                        <span className="icon feather-icon">
                          <Icons.Trash2 />
                        </span>
                      </Button>
                    ) : null}
                  </>
                ) : null}

                <Button
                  bsPrefix="btn-close"
                  className="drawer-close me-3"
                  onClick={closeDrawer}
                >
                  <span aria-hidden="true">×</span>
                </Button>
              </div>
            </div>
            <div className="drawer-body">
              <div className="drawer-content-wrap">
                {!readingMode || create || adminCreateEvent || clientManageEdit ? (
                  <EditEvent
                    eventDetails={eventDetails}
                    handleInputChange={handleInputChange}
                    handleDateChange={handleDateChange}
                    handlePhoneNoChange={handlePhoneNoChange}
                    phoneNo={phoneNo}
                    eventDateRef={eventDate}
                    eventTimeRef={eventTimeRef}
                    restaurantsPackageData={restaurantsPackageData}
                    setEventDetails={setEventDetails}
                    success={success}
                    create={create}
                    isAccepted={isAccepted}
                    handleCheckboxChange={handleCheckboxChange}
                    motherEvent={event}
                    setSelectedPackages={setSelectedPackages}
                    selectedPackages={selectedPackages}
                    isBoarded={restaurantInfo?.isBoarded}
                    startDate={startDate}
                    adminCreateEvent={adminCreateEvent}
                    restDay={restDay}
                    isValidReservationTime={isValidReservationTime}
                  />
                ) : (
                  <ViewEvents
                    eventDetails={eventDetails}
                    motherEvent={event}
                    adminNotification={adminNotification}
                    isBoarded={restaurantInfo.isBoarded}
                  />
                )}
                {/* {!readingMode && user.role !== "client" && eventDetails.packages && eventDetails.packages.length > 0 && restaurantsPackageData.map((item, index) => (
                  item.depositRequired === true && item._id === eventDetails.packages && (  
                    <Button
                      id="requestDepositBtn"
                      variant="primary"
                      className="drawer-edit-close"
                      onClick={() => {
                        const message = `Hi, this is ${restaurantInfo?.restaurantName}. Thanks for making reservation with us. \nDo note that a deposit is required for the package that you have selected. \nOur refund policy is stated as: ${restaurantInfo.refundPolicy} \n\nKindly make a deposit of RM ${packageDepositAmount}. Our bank info are as follows:\n ${restaurantInfo?.paymentDetails} \n\nDo remember to take a screenshot of the deposit made and send it to us as confirmation.\nThank you! \n\nSummary of your reservation details: \nBooking Name: ${eventDetails.title} \nDate: ${moment(eventDetails.eventDate).format("D MMMM YYYY")} \nTime: ${moment(new Date(`01/01/1970 ${event.eventTime}`)).format("hh:mm A")} \nNo. of Pax: ${eventDetails.noOfPax} \nPackage: ${eventDetails.packageName}`;
                        const phoneNumber = eventDetails?.phoneNo;
                        const url = (`https://wa.me/+60${phoneNumber}?text=${encodeURIComponent(message)}`);
                        window.open(url);
                      }}
                    >
                      Request Deposit
                    </Button>
                  )
                ))} */}
                {!readingMode || create || adminCreateEvent ? (
                  <div className="drawer-footer d-flex justify-content-center">
                    <Button
                      id="editDiscardBtn"
                      variant="secondary"
                      className="drawer-edit-close btn-sm me-3 font-monospace fs-7"
                      onClick={() => closeDrawer()}
                    >
                      discard
                    </Button>
                    {(!adminCreateEvent && !create && eventDetails.id !== eventId) ||
                      isSubmitting ||
                      (user.role === "client" && !create && !isAccepted && !restaurantInfo.isBoarded) ||
                      isDrawerOpen ? (
                        <Button
                          id="editSaveBtn"
                          variant="primary"
                          className="drawer-edit-close btn-sm me-3 font-monospace fs-7"
                          onClick={(e) => handleSubmit(e, true)}
                        >
                          Save
                        </Button>
                      ) : null}
                    <Button
                      id="editSaveBtn"
                      variant="primary"
                      className="drawer-edit-close btn-sm font-monospace fs-7"
                      disabled={
                        (!adminCreateEvent &&
                          !create &&
                          eventDetails.id !== eventId) ||
                        isSubmitting ||
                        (user.role === "client" && create && !isAccepted) ||
                        isDrawerOpen
                      }
                      onClick={(e) => handleSubmit(e, false)}
                    >
                      {isSubmitting ? (
                        <div>
                          <Spinner size="sm" />
                          <span style={{ marginLeft: "0.5rem" }}>Sending...</span>
                        </div>
                      ) : (
                        "send"
                      )}
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>            
          </div>
        </div>
      </Form>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
    </>
  );
};

export default EventsDrawer;
