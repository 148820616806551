import React, { useState, useRef, useEffect } from "react";
import { Col, Form, InputGroup, Row, Spinner, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as Icons from "react-feather";
import InputMask from "react-input-mask";
import moment from "moment";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import "../../../assets/dist/css/live-search.css";
import HkBadge from "../../../components/@hk-badge/@hk-badge";
import termsNConditions from "../../../assets/konfem_file/KONFEM USER TERMS OF USE.pdf";
import { useAuth } from "../../../services/authContext";
import useClientServices from "../../../services/clientService";


const EditEvent = ({
  eventDetails,
  handleInputChange,
  handleDateChange,
  handlePhoneNoChange,
  phoneNo,
  restaurantsPackageData,
  setEventDetails,
  create,
  isAccepted,
  handleCheckboxChange,
  motherEvent,
  setSelectedPackages,
  selectedPackages,
  isBoarded,
  startDate,
  adminCreateEvent,
  restDay,
  isValidReservationTime
}) => {
  const { user } = useAuth();
  const eventDateRef = useRef();
  const eventTimeRef = useRef();

  const { fetchClientInfo, clientInfo } = useClientServices();
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const [noEventDate, setNoEventDate] = useState(true);
  const [noEventTime, setNoEventTime] = useState(true);
  const [contactNumber, setContactNumber] = useState("");
  const [compareId, setCompareId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientName, setClientName] = useState("");

  const hideCalender = (ev, picker) => {
    picker.container.find(".calendar-table").hide();
  };

  useEffect(() => {
    if (eventDetails.eventDate) {
      setNoEventDate(false);
    } else {
      setNoEventDate(true);
    }
  }, [eventDetails]);

  useEffect(() => {
    if (user.role === "client" && !create) {
      setContactNumber(motherEvent.restaurantContact);
    } else if (
      user.role === "admin" &&
      motherEvent?._def?.extendedProps?.phoneNo
    ) {
      setContactNumber(motherEvent._def.extendedProps.phoneNo);
    } else if (user.role === "admin" && motherEvent?.phoneNo) {
      setContactNumber(motherEvent.phoneNo);
    }

    if (!create && !adminCreateEvent) {
      motherEvent?._def?.publicId
        ? setCompareId(motherEvent._def.publicId)
        : setCompareId(motherEvent._id);
    }
  }, [motherEvent, eventDetails]);
  
  useEffect(() => {
    if (eventDetails.eventTime) {
      setNoEventTime(false);
    } else {
      setNoEventTime(true);
    }
  }, [eventDetails.eventTime]);

  const handleRadioChange = (selectedOption) => {
    if (selectedOption.target.value === "Lunch") {
      const lunch = new Date('1970-01-01T12:00:00');
      handleInputChange({
        target: {
          name: selectedOption.target.name,
          value: lunch,
        },
      });
      eventTimeRef.current.setStartDate(lunch);
      eventTimeRef.current.setEndDate(lunch);
    } else if (selectedOption.target.value === "Dinner") {
      const dinner = new Date('1970-01-01T18:00:00');
      handleInputChange({
        target: {
          name: selectedOption.target.name,
          value: dinner,
        },
      });
      eventTimeRef.current.setStartDate(dinner);
      eventTimeRef.current.setEndDate(dinner);
    }
  };

  useEffect(() => {
    if (adminCreateEvent) {
      eventDateRef.current.setStartDate(getAdjustedDate(startDate));
      eventTimeRef.current.setStartDate(new Date('1970-01-01T12:00:00'));
      eventTimeRef.current.setEndDate(new Date('1970-01-01T12:00:00'));
    }

    if (create && user.role === "client") {
      eventDateRef.current.setStartDate(getAdjustedDate(new Date()));
      eventTimeRef.current.setStartDate(new Date('1970-01-01T12:00:00'));
      eventTimeRef.current.setEndDate(new Date('1970-01-01T12:00:00'));
    }
  }, [startDate, create]);

  useEffect(() => {
    if (user.role === "client" && clientInfo.clientContact) {
      setContactNumber(clientInfo.clientContact);
      setEventDetails((prevDetails) => ({
        ...prevDetails,
        phoneNo: clientInfo.clientContact,
      }));
    }

    if (user.role === "client" && clientInfo.clientName) {
      setClientName(clientInfo.clientName);
      setEventDetails((prevDetails) => ({
        ...prevDetails,
        title: clientInfo.clientName,
      }));
    }
  }, [clientInfo.clientContact, setEventDetails]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (clientInfo) {
      setPhoneNumber(clientInfo.clientContact);
      setContactNumber(clientInfo.clientContact);
      setClientName(clientInfo.clientName);
    }
  }, [clientInfo])

  const getAdjustedDate = (date) => {
    let adjustedDate = moment(date);
    while (restDay.includes(adjustedDate.day())) {
      adjustedDate = adjustedDate.add(1, 'days');
    }
    return adjustedDate.toDate();
  };

  // Handle checkbox change
  const handlePackageChange = (e, item) => {
    const { checked } = e.target;
    if (checked) {
      // Add package with default quantity 1
      setSelectedPackages([
        ...selectedPackages,
        { packageId: item._id, packageName: item.packageName, packageQuantity: 1 },
      ]);
    } else {
      // Remove package when unchecked
      setSelectedPackages(
        selectedPackages.filter((pkg) => pkg.packageId !== item._id)
      );
    }
  };

  // Handle quantity input change
  const handleQuantityChange = (e, item) => {
    const value = parseInt(e.target.value);
    // Update quantity, ensure at least 1 and valid number
    setSelectedPackages((packages) =>
      packages.map((pkg) =>
        pkg.packageId === item._id
          ? { ...pkg, packageQuantity: isNaN(value) || value < 1 ? 1 : value }
          : pkg
      )
    );
  };

  // Check if a package is selected
  const isPackageSelected = (packageId) =>
    selectedPackages.some((pkg) => pkg.packageId === packageId);

  return (
    <>
      {create || (eventDetails.id && eventDetails.id === compareId) || adminCreateEvent ? (
        <>
          <div className="event-head mb-2">
            <HkBadge
              bg="violet"
              indicator
              className="badge-indicator-xl flex-shrink-0 me-2"
            />

            <div>
              <Form.Group
                className="event-name mb-3 fs-7"
                controlId="editEventTitleInput"
              >
                <Form.Label>Booking Name*</Form.Label>
                <Form.Control
                  name="title"
                  className="fs-7"
                  onChange={handleInputChange}
                  value={eventDetails.title || clientName || ""}
                  required
                />
              </Form.Group>
            </div>
          </div>

          {/* <Form.Group
            className="mb-3"
            style={{ position: "relative" }}
            controlId="editEventNameInput"
          >
            <Form.Label>Event Name*</Form.Label>
            <InputGroup>
              <span className="input-affix-wrapper">
                <span className="input-prefix">
                  <span className="feather-icon">
                    <Icons.AlignCenter />
                  </span>
                </span>
                <Form.Control
                  name="eventName"
                  onChange={handleInputChange}
                  value={eventDetails.eventName || ""}
                  required
                />
              </span>
            </InputGroup>
          </Form.Group> */}

          <Form.Group className="mb-3 fs-7" style={{ position: "relative" }}>
            <Form.Label>Phone No.*</Form.Label>
            <InputGroup>
              <InputGroup.Text className="fs-7">+60</InputGroup.Text>
              <InputMask
                id="editPhoneNoInput"
                className="form-control fs-7"
                mask="99-99999999999999999999999"
                maskChar={null}
                name="phoneNo"
                onChange={handlePhoneNoChange}
                value={eventDetails.phoneNo || phoneNumber || ""}
                required
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 fs-7" controlId="editEventDateInput">
            <Form.Label>Date*</Form.Label>
            <InputGroup>
              <span className="input-affix-wrapper fs-7">
                <span className="input-prefix">
                  <span className="feather-icon">
                    <Icons.Calendar />
                  </span>
                </span>
                <DateRangePicker
                  ref={eventDateRef}
                  initialSettings={{
                    singleDatePicker: true,
                    autoApply: true,
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                    minDate: getAdjustedDate(new Date()),
                    startDate: eventDetails.eventDate
                      ? getAdjustedDate(new Date(eventDetails.eventDate))
                      : getAdjustedDate(new Date()),
                    isInvalidDate: (date) => {
                      const day = date.day();
                      return restDay.includes(day);
                    }
                  }}
                  onApply={(event, picker) => handleDateChange(event, picker.startDate)}
                >
                  <Form.Control
                    type="text"
                    name="eventDate"
                    className="fs-7"
                    onChange={() => {}}
                    required
                    readOnly
                  />
                </DateRangePicker>
              </span>
            </InputGroup>
            <span
              style={{
                color: noEventDate ? "red" : "inherit",
                fontSize: "0.65rem",
              }}
            >
              {noEventDate
                ? "Please select an event date*"
                : `Selected Date: ${
                  eventDetails.eventDate
                    ? moment(getAdjustedDate(eventDetails.eventDate)).format("D MMMM YYYY")
                    : ""
                }`}
            </span>
          </Form.Group>
          
          <Form.Group className="mb-3 fs-7" controlId="editEventTimeInput">
            <Form.Label>Time*</Form.Label>
            {/* <Row>
              <Col>
                <Form.Check
                  type="radio"
                  id="presetTimeLunch"
                  name="eventTime"
                  label="Lunch"
                  value="Lunch"
                  defaultChecked
                  onChange={handleRadioChange}
                  style={{ fontSize: '0.8rem' }} 
                />
              </Col>
              <Col>
                <Form.Check
                  type="radio"
                  id="presetTimeDinner"
                  name="eventTime"
                  label="Dinner"
                  value="Dinner"
                  onChange={handleRadioChange}
                  style={{ fontSize: '0.8rem' }} 
                />
              </Col>
            </Row> */}
            <InputGroup>
              <span className="input-affix-wrapper">
                <span className="input-prefix">
                  <span className="feather-icon">
                    <Icons.Clock className="fs-7"/>
                  </span>
                </span>
                <DateRangePicker
                  ref={eventTimeRef}
                  initialSettings={{
                    singleDatePicker: true,
                    autoApply: true,
                    timePicker: true,
                    timePicker24Hour: false,
                    timePickerIncrement: 15,
                    locale: {
                      format: "hh:mm A",
                    },
                    startDate: eventDetails.eventTime,
                    isInvalidDate: (date) => isValidReservationTime(date)
                  }}
                  onShow={hideCalender}
                  onApply={(event, picker) => handleDateChange(event, picker.startDate)}
                  onHide={(event, picker) => handleDateChange(event, picker.startDate)}
                >
                  <Form.Control
                    className="input-timepicker fs-7"
                    type="text"
                    name="eventTime"
                    onChange={() => {}}
                    required
                    readOnly
                  />
                </DateRangePicker>
              </span>
            </InputGroup>
            <span
              style={{
                color: noEventTime ? "red" : "inherit",
                fontSize: "0.75rem",
              }}
            >
              {noEventTime
                ? "Please select an event time*"
                : `Selected Time: ${
                  eventDetails.eventTime
                    ? moment(eventDetails.eventTime).format("hh:mm A") === "Invalid date" || moment(eventDetails.eventTime).format("hh:mm A") === "Invalid Date" ? eventDetails.eventTime : moment(eventDetails.eventTime).format("hh:mm A")
                    : ""
                }`}
            </span>
          </Form.Group>
          
          {isBoarded === 1 ? (
            <>
              {" "}
              {/* <Form.Group className="mb-3" controlId="editRSVPClosingDateInput">
                <Form.Label>Konfem Before</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="closingBefore"
                    aria-label="Amount (to the nearest dollar)"
                    type="number"
                    onChange={handleInputChange}
                    min="1"
                    value={eventDetails.closingBefore || ""}
                  />
                  <InputGroup.Text>day(s)</InputGroup.Text>
                </InputGroup>
              </Form.Group> */}

              <Form.Group className="mb-3 fs-7">
                <Form.Label>Select Packages (Optional)</Form.Label>
                <div className="px-2">
                  {restaurantsPackageData && Array.isArray(restaurantsPackageData) ? (
                    restaurantsPackageData.map((item) => (
                      item.state === "active" && (
                        <Row
                          key={item._id}
                          className="mb-2 align-items-center"
                          style={{
                            padding: "0 0", // Remove extra padding to align properly
                            margin: "0", // Ensure no extra margin
                          }}
                        >
                          {/* Checkbox for selecting package */}
                          <Col xs={7} className="d-flex align-items-center">
                            <Form.Check
                              type="checkbox"
                              id={`package-${item._id}`}
                              label={item.packageName}
                              onChange={(e) => handlePackageChange(e, item)}
                              checked={isPackageSelected(item._id)}
                              style={{ fontSize: "0.9rem", marginLeft: "0" }} // Ensures no extra margin
                            />
                          </Col>

                          {/* Quantity input box */}
                          <Col
                            xs={5}
                            className="d-flex justify-content-end align-items-center"
                          >
                            <Form.Control
                              type="number"
                              min="1"
                              placeholder="Qty"
                              value={
                                isPackageSelected(item._id)
                                  ? selectedPackages.find((pkg) => pkg.packageId === item._id)
                                      ?.packageQuantity || 1
                                  : ""
                              }
                              disabled={!isPackageSelected(item._id)}
                              onChange={(e) => handleQuantityChange(e, item)}
                              style={{
                                width: "70px",
                                textAlign: "center",
                                fontSize: "0.8rem",
                                padding: "0.375rem", // Consistent padding for input box
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    ))
                  ) : (
                    <div>No packages available</div>
                  )}
                </div>
              </Form.Group>
            </>
          ) : null}

          {user.role === "admin" && eventDetails.status ? (
            <Form.Group className="mb-3 fs-7" controlId="editEventStatus">
              <Form.Label>Status</Form.Label>
              <InputGroup>
                <Form.Select
                  className="fs-8"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setEventDetails({
                      ...eventDetails,
                      status: selectedValue,
                    });
                  }}
                >
                  {eventDetails.status && (
                    <option value={eventDetails.status} selected>
                      {eventDetails.status}
                    </option>
                  )}

                  {!eventDetails.status || eventDetails.status !== "Pending" ? (
                    <option value="Pending">Pending</option>
                  ) : null}
                  {!eventDetails.status ||
                  eventDetails.status !== "Konfemed" ? (
                      <option value="Konfemed">Konfem</option>
                    ) : null}
                  {!eventDetails.status ||
                  eventDetails.status !== "Rejected" ? (
                      <option value="Rejected">Reject</option>
                    ) : null}
                </Form.Select>
              </InputGroup>
            </Form.Group>
          ) : null}

          <Form.Group className="mb-3 fs-7" controlId="editEventTableInput">
            <Form.Label>No. of Table</Form.Label>
            <InputGroup>
              <span className="input-affix-wrapper">
                <span className="input-prefix">
                  <span className="feather-icon">
                    <Icons.Hash className="fs-7"/>
                  </span>
                </span>
                <Form.Control
                  className="fs-7"
                  name="noOfTable"
                  type="number"
                  onChange={handleInputChange}
                  value={eventDetails.noOfTable || ""}
                  min="1"
                />
              </span>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 fs-7" controlId="editEventPaxInput">
            <Form.Label>No. of Pax</Form.Label>
            <InputGroup>
              <span className="input-affix-wrapper">
                <span className="input-prefix">
                  <span className="feather-icon">
                    <Icons.Users className="fs-7"/>
                  </span>
                </span>
                <Form.Control
                  className="fs-7"
                  name="noOfPax"
                  type="number"
                  onChange={handleInputChange}
                  value={eventDetails.noOfPax || ""}
                  min="1"
                />
              </span>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 fs-7" controlId="editEventRemarkInput">
            <Form.Label>Remark</Form.Label>
            <InputGroup>
              <span className="input-affix-wrapper">
                <Form.Control
                  className="fs-7"
                  name="remark"
                  as="textarea"
                  rows={4}
                  onChange={handleInputChange}
                  value={eventDetails.remark || ""}
                />
              </span>
            </InputGroup>
          </Form.Group>
          <div> 
            <p className="fs-8">The restaurant will hold your table for up to 15mins past your reservation time.</p>
            <p className="fs-8">Cancellation Policy:</p>
            <ul style={{ listStyleType: 'disc' }}>
              <li className="fs-8">If you can't make it to your reservation, please contact the Restaurant to cancel your reservation in advance.</li>
            </ul>
          </div>

          {create ? (
            <Form.Group className="mb-3" controlId="termsAndConditions">
              <Form.Check
                type="checkbox"
                id="termsAndConditionsCheckbox"
                label={
                  <span>
                    I agree to the{" "}
                    <a href="#" onClick={() => window.open(termsNConditions)}>
                      Terms & Conditions
                    </a>
                  </span>
                }
                checked={isAccepted}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          ) : null}
        </>
      ) : (
        <div className="text-center mt-50">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default EditEvent;
