import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Dropdown,
  Collapse,
} from "react-bootstrap";
import { 
  FaWhatsapp, 
  FaMapMarkerAlt, 
  FaClock, 
  FaPhone, 
  FaWifi, 
  FaParking, 
  FaSnowflake, 
  FaWheelchair, 
  FaPaw, 
  FaUmbrellaBeach,
  FaLeaf, 
  FaSeedling, 
  FaBreadSlice,
  FaBan, 
  FaUtensils,
  FaCar,
  FaHourglassHalf,
  FaChild,
  FaMusic,
  FaMosque,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faShareNodes, faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from 'framer-motion';
import moment from "moment";

import defaultImage from "../../../assets/dist/img/default_photo.png";
import { useAuth } from "../../../services/authContext";
import EventsDrawer from "../../../layout/Header/EventsDrawer/EventsDrawer";
import Ratings from "../RatingsReviews/RestaurantsRating";
import Photos from "../RestaurantPhotos/RestaurantPhotos";
import MetaTags from "./Metatags";


// eslint-disable-next-line no-undef
const frontendUri = process.env.REACT_APP_FRONTEND_DOMAIN;
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ProfileIntro = ({ restaurantInfo, clientInfo }) => {
  const history = useHistory();
  const [createEvent, setCreateEvent] = useState(false);
  const handleGoToLoginClick = () => {
    history.push({
      pathname: "/auth/",
      state: { restaurantId: restaurantInfo._id },
    });
  };

  const { user } = useAuth();
  const drawerController = () => {
    setClientCreate(true);
    setCreateEvent(!createEvent);
  };

  const sunBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 0
  );
  const monBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 1
  );
  const tueBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 2
  );
  const wedBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 3
  );
  const thuBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 4
  );
  const friBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 5
  );
  const satBusinessHours = restaurantInfo.businessHours?.find(
    (businessHour) => businessHour.day === 6
  );

  const daysOfWeek = [
    { day: "Sunday", businessHours: sunBusinessHours },
    { day: "Monday", businessHours: monBusinessHours },
    { day: "Tuesday", businessHours: tueBusinessHours },
    { day: "Wednesday", businessHours: wedBusinessHours },
    { day: "Thursday", businessHours: thuBusinessHours },
    { day: "Friday", businessHours: friBusinessHours },
    { day: "Saturday", businessHours: satBusinessHours },
  ];

  const [open, setOpen] = useState(false);
  const [clientCreate, setClientCreate] = useState(false);
  
  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");

    if (wrapperElement) {
      if (createEvent) {
        wrapperElement.style.zIndex = "90";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };

  useEffect(() => {
    applySpecialStyles();

    if(!createEvent){
      setClientCreate(false);
    }
  }, [createEvent]);

  const [visibleChar, setVisibleChar] = useState(0);
  useEffect(() => {
    const stripHtmlTags = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      setVisibleChar((div.textContent || div.innerText || '').length);
    };
    stripHtmlTags(restaurantInfo.description);
  }, [restaurantInfo.description]);

  const handleShareButtonClick = async (id) => {
    // Update share button clicks for each restaurant
    const timestamp = new Date();
    const username = clientInfo?.clientName || "Unknown";
    const userId = clientInfo?._id || "Unknown";
    const data = { timestamp, username, userId };
    
    await fetch(`${backendUri}/api/v0/public/restaurants/updateShareClicks/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // Share restaurant via whatsapp
    const message = `Check out this restaurant: ${restaurantInfo.restaurantName} - \n ${frontendUri}/restaurants/${restaurantInfo._id}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  }

  const handleLandlineReservationButtonClick = async (id, landlineContact) => {
    // Update landline reservation button clicks for each restaurant
    const timestamp = new Date();
    const username = clientInfo?.clientName || "Unknown";
    const userId = clientInfo?._id || "Unknown";
    const data = { timestamp, username, userId };
    
    await fetch(`${backendUri}/api/v0/public/restaurants/updateLandlineReservationClicks/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // Call restaurant via landline to make reservation
    window.location.href = `tel:+60${landlineContact}`;
  }

  const amenityIcons = {
    'wifi': <FaWifi />,
    'public parking': <FaParking />,
    'private parking': <FaCar />,
    'waiting area': <FaHourglassHalf />,
    'kids play area': <FaChild />,
    'air conditioning': <FaSnowflake />,
    'wheelchair accessible': <FaWheelchair />,
    'pet friendly': <FaPaw />,
    'outdoor seating': <FaUmbrellaBeach />,
    'private dining': <FaUtensils />,
    'vegetarian options': <FaLeaf />,
    'vegan options': <FaSeedling />,
    'gluten free options': <FaBreadSlice />,
    'non smoking': <FaBan />,
    'live band': <FaMusic />,
    'halal': <FaMosque />,
    'muslim friendly': <FaMosque />,
  };

  const renderAmenities = (amenities) => {
    return amenities.map((amenity, index) => (
      <div key={index} className="fs-8" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span style={{ fontSize: '14px' }}>
          {amenityIcons[amenity.toLowerCase()] || <i className="fas fa-question"></i>}
        </span>
        <span>{amenity}</span>
      </div>
    ));
  };

  const metaTagsData = {
    title: `${restaurantInfo.restaurantName} | Konfem Reservation`,
    description: `Discover ${restaurantInfo.restaurantName} - a top dining destination in town. Reserve now!`,
    image: restaurantInfo.restaurantLogo,
    url: `https://reservation.konfem.com/restaurants/${restaurantInfo._id}`,
    // url: `${frontendUri}/restaurants/${restaurantInfo._id}`,
    restaurant: restaurantInfo,
  }

  return (
    <>
      <MetaTags {...metaTagsData} />
      <div className="profile-intro">
        <Card className="card-flush mw-800p bg-transparent">
          <Button 
            onClick={() => handleShareButtonClick(restaurantInfo._id)} 
            className="btn btn-sm shadow" 
            style={{ 
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'none', 
              border: '0.5px solid #ed9c00',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              color: '#ed9c00'
            }}
          >
            <FontAwesomeIcon icon={faShareNodes} />
          </Button>
          <Card.Body>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <div className="avatar avatar-xl avatar-rounded position-relative mb-4" style={{ padding: '5px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '50%', backgroundColor: '#fff' }}>
                <img
                  src={
                    restaurantInfo.restaurantLogo
                      ? restaurantInfo.restaurantLogo
                      : defaultImage
                  }
                  alt="user"
                  className="avatar-img border border-1 border-light"
                  style={{ borderRadius: '50%', objectFit: 'cover', width: '100%', height: '100%' }}
                />
              </div>
              <div className="d-flex flex-column ms-5 mb-2 fw-bold fs-6" style={{ flex: 1 }}>
                <span className="mb-1">{restaurantInfo.restaurantName}</span>
                <Ratings restaurantInfo={restaurantInfo} />
              </div>
            </div>
            <div>
              {restaurantInfo.addressLink ? (
                <a
                  href={restaurantInfo.addressLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                  id="addressLink"
                >
                  <div>
                    <FaMapMarkerAlt
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: '0.8rem' }}>
                    <span className="contact-info text-decoration-underline">
                      {restaurantInfo.restaurantAddress}
                    </span>
                  </div>
                </a>
              ) : (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    restaurantInfo.restaurantAddress
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                  id="addressLink"
                >
                  <div>
                    <FaMapMarkerAlt
                      style={{ marginRight: "5px", fontSize: "20px" }}
                    />
                  </div>
                  <div style={{ marginLeft: "10px", fontSize: '0.8rem' }}>
                    <span className="contact-info text-decoration-underline">
                      {restaurantInfo.restaurantAddress}
                    </span>
                  </div>
                </a>
              )}
            </div>
            {restaurantInfo.restaurantContact ? (
              <>
                <ul className="list-inline fs-7 mt-2 mb-0">
                  <li className="list-inline-item d-sm-inline-block d-block mb-sm-0 mb-1 me-3">
                    <a
                      href={`https://wa.me/+60${restaurantInfo.restaurantContact}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <FaWhatsapp
                          style={{ marginRight: "5px", fontSize: "20px" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px", fontSize: '0.8rem' }}>
                        <span className="contact-info text-decoration-underline">
                        +60{restaurantInfo.restaurantContact}
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </>
            ) : null}
            {restaurantInfo.restaurantContactLandline ? (
              <>
                <ul className="list-inline fs-7 mt-2 mb-0">
                  <li className="list-inline-item d-sm-inline-block d-block mb-sm-0 mb-1 me-3">
                    <a
                      href={`tel:+60${restaurantInfo.restaurantContactLandline}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <FaPhone
                          style={{ marginRight: "5px", fontSize: "18px" }}
                        />
                      </div>
                      <div style={{ marginLeft: "10px", fontSize: '0.8rem' }}>
                        <span className="contact-info text-decoration-underline">
                          +60{restaurantInfo.restaurantContactLandline}
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </>
            ) : null}
            <br />
            <div>
              <Dropdown
                onClick={() => setOpen(!open)}
                aria-controls="business-hours-collapse"
                aria-expanded={open}
                style={{ marginTop: "-18px" }}
                id="defaultBusinessHour"
              >
                <div style={{ display: "flex", alignItems: "center", padding: "10px", cursor: "pointer" }}>
                  <div style={{ marginRight: "3px", marginLeft: "-8px", color: "#d18615", fontSize: '18px' }}>
                    <FaClock />
                  </div>
                  <div style={{ marginRight: "5px", marginLeft: "15px" }}>
                    <span style={{ color: "#d18615" }}>
                      {`${daysOfWeek[new Date().getDay()].day}:`}
                    </span>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <span style={{ color: "#d18615" }}>
                      {daysOfWeek[new Date().getDay()].businessHours ? (
                        <>
                          {daysOfWeek[new Date().getDay()].businessHours.isRestDay === 0 ? (
                            `${moment(
                              daysOfWeek[new Date().getDay()].businessHours.startTime).format("hh:mm A")} - 
                            ${moment(
                              daysOfWeek[new Date().getDay()].businessHours.endTime).format("hh:mm A")}` +
                            (daysOfWeek[new Date().getDay()].businessHours.setTime2 ? 
                              `, ${moment(
                                daysOfWeek[new Date().getDay()].businessHours.startTime2).format("hh:mm A")} - 
                            ${moment(
                                daysOfWeek[new Date().getDay()].businessHours.endTime2).format("hh:mm A")}` : '')
                          ) : daysOfWeek[new Date().getDay()].businessHours.isRestDay === 1 ? (
                            <span style={{ color: "red" }}>Closed</span>
                          ) : (
                            <span style={{ color: "#d18615" }}>-</span>
                          )}
                        </>
                      ) : (
                        <span style={{ color: "#d18615" }}>-</span>
                      )}
                    </span>
                  </div>
                  <div style={{ marginLeft: "auto", color: "#d18615" }}>
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                  </div>
                </div>
              </Dropdown>
              <Collapse in={open} id="business-hours-collapse">
                <div>
                  {daysOfWeek.map(
                    (day, index) =>
                      index !== new Date().getDay() && (
                        <div
                          key={index}
                          style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                        >
                          <div style={{ minWidth: "100px", marginLeft: "38px", marginRight: "5px" }}>
                            <span>{day.day}:</span>
                          </div>
                          <div>
                            {day.businessHours ? (
                              <>
                                {day.businessHours.isRestDay === 0 ? (
                                  <span style={{ color: "#A9A9A9" }}>
                                    {moment(day.businessHours.startTime).format("hh:mm A")} {"- "}
                                    {moment(day.businessHours.endTime).format("hh:mm A")}
                                    <br />
                                    {day.businessHours.setTime2 && moment(day.businessHours.startTime2).format("hh:mm A")}
                                    {day.businessHours.setTime2 && " - "}
                                    {day.businessHours.setTime2 && moment(day.businessHours.endTime2).format("hh:mm A")}
                                  </span>
                                ) : day.businessHours.isRestDay === 1 ? (
                                  <span style={{ color: "red" }}>Closed</span>
                                ) : (
                                  <span style={{ color: "#A9A9A9" }}>-</span>
                                )}
                              </>
                            ) : (
                              <span style={{ color: "#A9A9A9" }}>-</span>
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Collapse>
            </div>
            
            {restaurantInfo.description && restaurantInfo.description !== "" && visibleChar !== 0 ? (
              <div 
                style={{
                  margin: '10px auto',
                  padding: '10px',
                  border: '2px solid #ddd',
                  borderRadius: '5px',
                  color: '#6c757d',
                }}
              >
                <h6 className="mb-3 fw-bold">Description</h6>
                <div dangerouslySetInnerHTML={{ __html: restaurantInfo.description }} />
              </div>
            ) : null}

            {restaurantInfo.amenitiesTags && restaurantInfo.amenitiesTags.length > 0 ? (
              <div style={{ border: '2px solid #ddd', padding: '10px', borderRadius: '5px', marginTop: '20px' }}>
                <h6 className="mb-3 fw-bold">Amenities</h6>
                <div className="d-flex flex-wrap" style={{ gap: '15px' }}>
                  {renderAmenities(restaurantInfo.amenitiesTags || [])}
                </div>
              </div>
            ) : null}

            {restaurantInfo.reservationsMethod !== "noReservation" ? (
              user.role === "admin" || user.role === "superAdmin" ? null : user.role === "client" ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <motion.div
                      animate={{ x: [0, 10, 0] }}
                      transition={{ repeat: Infinity, duration: 0.6 }}
                    >
                      <FontAwesomeIcon icon={faHandPointRight} style={{ fontSize: '25px', marginRight: '15px' }} />
                    </motion.div>
                    {restaurantInfo.restaurantContact ? (
                      <Button
                        id="createEvent"
                        className="btn btn-outline-primary mt-3"
                        onClick={drawerController}
                        style={{ fontSize: '0.9rem' }}
                      >
                        Secure A Spot Here!
                      </Button>
                    ) : (
                      <Button
                        id="createEvent"
                        className="btn btn-outline-primary mt-3"
                        onClick={() => handleLandlineReservationButtonClick(restaurantInfo._id, restaurantInfo.restaurantContactLandline)}
                        style={{ fontSize: '0.9rem' }}
                      >
                        Secure A Spot Here!
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <motion.div
                      animate={{ x: [0, 10, 0] }}
                      transition={{ repeat: Infinity, duration: 0.6 }}
                    >
                      <FontAwesomeIcon icon={faHandPointRight} style={{ fontSize: '25px', marginRight: '15px' }} />
                    </motion.div>
                    <Button
                      id="createEvent"
                      className="btn btn-outline-primary mt-3"
                      onClick={handleGoToLoginClick}
                      style={{
                        fontSize: '0.9rem',
                        backgroundColor: 'primary',
                        borderRadius: '5px',
                        padding: '8px 10px',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      Secure A Spot Here!
                    </Button>
                  </div>
                </>
              )
            ) : (
              <Button
                id="notOpenForReservations"
                className="mt-3 fs-7"
                style={{ fontSize: '0.9rem' }}
                disabled
              >
                {"Not Taking Reservations Right Now, Check Back Soon!"}
              </Button>
            )}
            <Photos restaurantInfo={restaurantInfo} />
          </Card.Body>
        </Card>
        <EventsDrawer
          show={createEvent}
          onClose={drawerController}
          setClientCreate={setClientCreate}
          create={clientCreate}
          restaurantId={restaurantInfo._id}
          isBoarded={restaurantInfo.isBoarded}
          reservationsMethod={restaurantInfo.reservationsMethod}
          restaurantContact={restaurantInfo.restaurantContact}
        />
      </div>
    </>
  );
};

export default ProfileIntro;
