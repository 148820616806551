import React, { useState, useEffect } from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";


const Ratings = ({ restaurantInfo }) => {
  const [ratings, setRatings] = useState(0);

  useEffect(() => {
    setRatings(restaurantInfo.ratings || 0);
  }, [restaurantInfo]);

  const renderStars = (rating, size='11px') => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= Math.floor(rating)) {
        stars.push(<FaStar key={i} color="gold" style={{ fontSize: size }} />);
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(<FaStarHalfAlt key={i} color="gold" style={{ fontSize: size }} />);
      } else {
        stars.push(<FaStar key={i} color="lightgray" style={{ fontSize: size }} />); 
      }
    }
    return stars;
  };

  return (
    <>
      {ratings > 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '5px', fontSize: '11px', fontWeight: 'bold' }}>{ratings}</span>
          {renderStars(ratings)}
        </div>
      )}
    </>
  );
}

export default Ratings;
