import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Modal } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import * as Icons from "react-feather";


const EventCards = ({ eventsData, readAndEditEvent, DeleteEvent }) => {
  const [showModal, setShowModal] = useState(false);
  const [whatsappMessageLink, setWhatsappMessageLink] = useState("");
  const [currentSelectedEvent, setCurrentSelectedEvent] = useState({});

  const handleWhatsappClick = (e, event, message) => {
    if (!event?.restaurantId?.isBoarded || event?.restaurantId?.reservationsMethod !== "konfemApp") {
      e.preventDefault();
      setWhatsappMessageLink(message);
      setCurrentSelectedEvent(event);
      setShowModal(true);
    }
  };

  const handleConfirm = () => {
    setShowModal(false);
    window.location.href = `whatsapp://send?text=${encodeURIComponent(whatsappMessageLink)}`;
  };

  const getMessage = (event) => {
    if (event?.restaurantId?.addressLink) {
      return `I've Konfem reservation at this Restaurant: ${event?.restaurantId?.restaurantName}\nBooking name: ${event?.title}\nDate: ${event?.eventDate}\nTime: ${event?.eventTime}\nLocation: ${event?.restaurantId?.addressLink}`;
    } else {
      return `I've Konfem reservation at this Restaurant: ${event?.restaurantId?.restaurantName}\nBooking name: ${event?.title}\nDate: ${event?.eventDate}\nTime: ${event?.eventTime}\nLocation: https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        `${event?.restaurantId?.addressLine1}, ${event?.restaurantId?.addressLine2}, ${event?.restaurantId?.zipCode}, ${event?.restaurantId?.city}, ${event?.restaurantId?.country}`
      )}`;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Konfemed":
        return "#3CD63E";
      case "Rejected":
        return "#FF0000";
      case "Pending":
        return "#d18615";
      default:
        return "#000000";
    }
  };

  return (
    <>
      {eventsData.map((event, index) => (
        <Col key={index} xl={4} sm={6}>
          <Card>
            <div className="mt-2 d-flex justify-content-end">
              <span className="me-3">
                <Icons.Eye
                  size={16}
                  style={{ cursor: 'pointer' }}
                  onClick={() => readAndEditEvent(event._id, false)}
                />
              </span>
              {(event?.restaurantId?.isBoarded && event?.status === "Pending" && new Date() < new Date(event?.eventDate).setDate(new Date(event?.eventDate).getDate() + 1)) ||
                (!event?.restaurantId?.isBoarded && new Date() <= new Date(event?.eventDate).setDate(new Date(event?.eventDate).getDate() + 1)) ? (
                  <>
                    <span className="me-3">
                      <Icons.Edit2
                        size={16}
                        style={{ cursor: 'pointer' }}
                        onClick={() => readAndEditEvent(event._id, true)}
                      />
                    </span>
                    <span className="me-3">
                      <Icons.Trash2
                        size={16}
                        style={{ cursor: 'pointer' }}
                        onClick={() => DeleteEvent(event._id)}
                      />
                    </span>
                  </>
                ) : null}
            </div>
            <Card.Body className="d-block justify-content-between align-items-center">
              <div>
                <Card.Title className="fw-bold fs-6 mb-3">
                  {`${event.title} - ${event?.restaurantId?.restaurantName}`}
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted fs-8">
                  {event.eventDate} - {event.eventTime}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted fs-8">
                  {event.packages && event.packages.length > 0 ? (
                    `Packages Selected: ${event.packages.map((pkg) => `${pkg.packageQuantity} ${pkg.packageName}`).join(", ")}`
                  ) : null}
                </Card.Subtitle>
                <Card.Subtitle className="mb-5 display-flex align-items-center">
                  {event?.restaurantId?.isBoarded && event?.restaurantId?.reservationsMethod === "konfemApp" ? (
                    <div className="fs-8">
                      <span className="text-muted">Status by Restaurant: </span>
                      <span className="fs-8" style={{ color: getStatusColor(event.status) }}>
                        &nbsp; {event.status}
                      </span>
                    </div>
                  ) : null} 
                </Card.Subtitle>
              </div>
              <Card.Subtitle>
                <div className="mb-2">
                  <span className="text-muted fw-bold fs-8">Don't "Bo Jio" </span>
                  <span className="text-muted fs-8">(Share this link with family & friends):</span>
                </div>
                {event?.restaurantId?.addressLink ? (
                  <a
                    className="display-flex align-items-center"
                    href={`whatsapp://send?text=${encodeURIComponent(
                      `I've Konfem reservation at this Restaurant: ${event?.restaurantId?.restaurantName}\nBooking name: ${event?.title}\nDate: ${event?.eventDate}\nTime: ${event?.eventTime}\nLocation: ${event?.restaurantId?.addressLink}`
                    )}`}
                    id="addressLink"
                    onClick={(e) => handleWhatsappClick(e, event, getMessage(event))}
                  >
                    <span className="me-2">
                      <FaMapMarkerAlt/>
                    </span>
                    <span className="text-decoration-underline fs-8">
                      {`${event?.restaurantId?.addressLine1}, ${event?.restaurantId?.addressLine2}, ${event?.restaurantId?.zipCode}, ${event?.restaurantId?.city}, ${event?.restaurantId?.country}`}
                    </span>
                  </a>
                ) : (
                  <a
                    className="display-flex align-items-center"
                    href={`whatsapp://send?text=${encodeURIComponent(
                      `I've Konfem reservation at this Restaurant: ${event?.restaurantId?.restaurantName}\nBooking name: ${event?.title}\nDate: ${event?.eventDate}\nTime: ${event?.eventTime}\nLocation: https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        `${event?.restaurantId?.addressLine1}, ${event?.restaurantId?.addressLine2}, ${event?.restaurantId?.zipCode}, ${event?.restaurantId?.city},  ${event?.restaurantId?.country}`
                      )}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="addressLink"
                    onClick={(e) => handleWhatsappClick(e, event, getMessage(event))}
                  >
                    <span className="me-2">
                      <FaMapMarkerAlt/>
                    </span>
                    <span className="ms-2 text-decoration-underline fs-8">
                      {`${event?.restaurantId?.addressLine1}, ${event?.restaurantId?.addressLine2}, ${event?.restaurantId?.zipCode}, ${event?.restaurantId?.city}, ${event?.restaurantId?.country}`}
                    </span>
                  </a>
                )}
              </Card.Subtitle>
            </Card.Body>
          </Card>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title className="font-monospace fs-6">Confirm Your Reservation Date and Time</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-monospace fs-8" dangerouslySetInnerHTML={{ __html: `
              Please confirm the correct reservation<br /> 
              date and time before sharing.<br />
              <br />
              Current Date: ${currentSelectedEvent?.eventDate}<br />
              Time: ${currentSelectedEvent?.eventTime}
            ` }}>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-sm font-monospace fs-8" variant="secondary" onClick={() => { setShowModal(false); readAndEditEvent(currentSelectedEvent._id, true) }}>
                {"No - Edit"}
              </Button>
              <Button className="btn-sm font-monospace fs-8" variant="primary" onClick={handleConfirm}>
                {"Yes - Proceed"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      ))}
    </>
  );
};

export default EventCards;