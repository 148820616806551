import React, { useState, useEffect } from "react";
import { Button, Col, Row, Container, Spinner, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../../services/firebaseService"
import { getToken } from "firebase/messaging";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";

import fatalErrorImg from "../../../assets/dist/img/macaroni-fatal-error.png";
import EventsDrawer from "../../../layout/Header/EventsDrawer/EventsDrawer";
import useEventServices from "../../../services/eventService";
import useClientServices from "../../../services/clientService";
import { useAuth } from "../../../services/authContext";
import EventCards from "./EventCards";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const vapidKey = process.env.VAPID_KEY;
const GetAllEvents = () => {
  const [showEventInfo, setShowEventInfo] = useState(false);
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const [targetEvent, setTargetEvent] = useState("");
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(true);
  const [responseEventsData, setResponseEventsData] = useState("");
  const { user } = useAuth();
  const location = useLocation();
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [eventId, setEventId] = useState("");
  const {
    fetchEventInfo,
    eventInfo,
    getEvents,
    responseUpcomingEvents,
    responsePastEvents,
    success,
    deleteEvent,
  } = useEventServices(); 
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  // Manage client dashbaord edit and delete events
  const [clientManageEdit, setClientManageEdit] = useState(false);

  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");
    if (wrapperElement) {
      if (showEventInfo) {
        wrapperElement.style.zIndex = "90";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };
  useEffect(() => {
    applySpecialStyles();
  }, [showEventInfo]);

  useEffect(() => {
    if (window.location.pathname !== "/app/dashboard") {
      setIsUpcomingEvent(false);
      if (responsePastEvents.length <= 0) {
        setResponseEventsData("No historical events at the moment");
      } else {
        setResponseEventsData(responsePastEvents);
      }
    } else {
      if (responseUpcomingEvents.length <= 0) {
        setResponseEventsData("No upcoming events at the moment");
      } else {
        setResponseEventsData(responseUpcomingEvents);
      }
    }
  }, [
    window.location.pathname,
    responseUpcomingEvents,
    responsePastEvents,
    eventInfo,
  ]);

  useEffect(() => {
    if (token && user.role === "client") {
      getEvents();
      const interval = setInterval(async () => {
        await getEvents();
      }, 5000); // 10000 milliseconds = 10 seconds

      return () => clearInterval(interval);
    }
  }, []);

  const closeSideBar = () => {
    setShowEventInfo(!showEventInfo);
    setTargetEvent("");
  };

  const readAndEditEvent = (eventsId, clientManageEdit) => {
    setTargetEvent("");
    setShowEventInfo(true);
    fetchEventInfo(eventsId);
    setClientManageEdit(clientManageEdit);
  };

  useEffect(() => {
    setTargetEvent(eventInfo);
  }, [eventInfo]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      fetchClientInfo(decodedToken.userId);
    }
  }, [decodedToken]);

  const requestNotificationPermission = async (uid) => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await saveDeviceToken(uid);
    } else {
      console.log('Unable to get permission');
    }
  };

  const saveDeviceToken = async (uid) => {
    const fcmToken = await getToken(messaging, {
      vapidKey: vapidKey
    });

    try {
      fetch(`${backendUri}/api/v0/client/deviceToken/${uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify({fcmToken})
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.message, {
              containerId: "toast-container",
            });
          }
        })
    } catch (error) {
      console.error("Error updating device token:", error);
    }
  };

  const handleAgreeNotification = () => {
    requestNotificationPermission(decodedToken.userId);
    setShowNotificationDialog(false);
  };

  useEffect(() => {
    const fetchClientTokenAndCheck = async () => {
      if (clientInfo) {
        const clientToken = await getToken(messaging, {
          vapidKey: vapidKey
        });
        const deviceToken = clientInfo.deviceToken;
        if (!deviceToken || deviceToken.length === 0 || !deviceToken.includes(clientToken)) {
          setShowNotificationDialog(true);
        }
      }
    };

    fetchClientTokenAndCheck();
  }, [clientInfo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get("eventId");
    
    if (eventId) {
      const fetchEventDetails = async () => {
        try {
          const event = await fetchEventInfo(eventId);
          if (event) {
            setTargetEvent(event);
            setShowEventInfo(true);
          }
        } catch (error) {
          console.error("Failed to fetch event details:", error);
        }
      };
      fetchEventDetails();
    }
  }, [location.search]);

  useEffect(() => {
    if (eventId !== "") {
      const fetchEventDetails = async () => {
        try {
          const event = await fetchEventInfo(eventId);
          if (event) {
            setTargetEvent(event);
            setShowEventInfo(true);
            setEventId("");
          }
        } catch (error) {
          console.error("Failed to fetch event details:", error);
        }
      };
      fetchEventDetails();
    }
  }, [eventId]);

  useEffect(() => {
    if (user.role === "client") {
      onMessage(messaging, (payload) => {
        if (payload && payload.notification) {
          const { title, body } = payload.notification;
          const payloadEventId = payload.data.eventId;
          if (payloadEventId) {
            setEventId(payloadEventId);
          }
          if (body) {
            if (Notification.permission === "granted") {
              new Notification(title, { body });
            }
          } else {
            console.error("Notification body is undefined.");
          }
        } else {
          console.error("Payload notification is undefined.");
        }
      });
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
          .then((registration) => {
            navigator.serviceWorker.addEventListener('message', (event) => {
              const payloadEventId = event.data.data.eventId;
              if (payloadEventId) {
                setEventId(payloadEventId);
              }
            });
          }).catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      }
    }
  }, []);

  /*Event Delete*/
  const DeleteEvent = (deleteEventId) => {
    Swal.fire({
      html: '<div class="mb-3"><i class="ri-delete-bin-6-line fs-5 text-danger"></i></div><h5 class="text-danger">Delete Event ?</h5><p>Deleting a event will permanently remove from your list.</p>',
      customClass: {
        confirmButton: "btn btn-outline-secondary text-danger",
        cancelButton: "btn btn-outline-secondary text-grey",
        container: "swal2-has-bg",
      },
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, Delete Event",
      cancelButtonText: "No, Keep Event",
      reverseButtons: true,
      showDenyButton: false,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteEvent(deleteEventId);

        Swal.fire({
          html: '<div class="d-flex align-items-center"><i class="ri-delete-bin-5-fill me-2 fs-3 text-danger"></i><h5 class="text-danger mb-0">Event has been deleted!</h5></div>',
          timer: 2000,
          customClass: {
            content: "p-0 text-left",
            actions: "justify-content-start",
          },
          showConfirmButton: false,
          buttonsStyling: false,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    });
    document.querySelector(".swal2-confirm").id = "delete-confirm-button";
    document.querySelector(".swal2-cancel").id = "delete-cancel-button";
  };

  return (
    <div className="font-monospace">
      <div className="p-2 text-center">
        <h6 id="clientEventView" className="fw-bold ">
          Manage Your Reservations
        </h6>
      </div>
      {/* check if path is /app/dashboard or /app/ended */}
      {responseEventsData && responseUpcomingEvents && responsePastEvents ? (
        <Row className="d-flex justify-content-center">
          {!Array.isArray(responseEventsData) ? (
            <Container className="container-fluid">
              <div className="hk-pg-body">
                <Row>
                  <Col xl={7} lg={6} className="d-lg-block d-none">
                    <div className="auth-content py-md-0 py-8">
                      <Row>
                        <Col xl={12} className="text-center">
                          <img
                            src={fatalErrorImg}
                            className="img-fluid w-sm-80 w-50"
                            alt="login"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xl={5} lg={6} md={7} sm={10}>
                    <div className="auth-content py-8">
                      <div className="w-100">
                        <Row>
                          <Col xxl={9} xl={8} lg={11}>
                            <h1 className="display-4 fw-bold mb-2" id="noEvent">
                              {responseEventsData}
                            </h1>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            <>
              <Container className="container-fluid">
                <Row className="py-3 px-3">
                  <EventCards eventsData={responseEventsData} readAndEditEvent={readAndEditEvent} DeleteEvent={DeleteEvent} />
                </Row>
                {showEventInfo ? (
                  <EventsDrawer
                    show={showEventInfo}
                    event={targetEvent}
                    onClose={closeSideBar}
                    readAndEditEvent={readAndEditEvent}
                    token={token}
                    restaurantId={targetEvent.restaurantId}
                    clientManageEdit={clientManageEdit}
                  />
                ) : null}
              </Container>
            </>
          )}
        </Row>
      ) : (
        <div className="text-center mt-50">
          <Spinner />
        </div>
      )}

      <Modal show={showNotificationDialog} onHide={() => setShowNotificationDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to get updates for your reservation?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button id="no" variant="secondary" onClick={() => setShowNotificationDialog(false)}>
            No
          </Button>
          <Button id="yes" variant="primary" onClick={handleAgreeNotification}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
    </div>
  );
};

export default GetAllEvents;
