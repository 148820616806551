import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Plus } from "react-feather";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

import AddModal from "./AddFormModal";
import DeleteModal from "./DeleteModal";
import EditFormModal from "./EditFormModal";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RestaurantAdminManagement = () => {
  // Access token variable
  const [Token, setToken] = useState(Cookies.get("token"));

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Modal show variable
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  // Fetch data use
  const [lists, setList] = useState([]);

  // ID get
  const [adminId, setAdminId] = useState([]);

  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  useEffect(() => {
    fetchData(Token);
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.adminName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.adminContact &&
            item.adminContact
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.restaurantName &&
            item.restaurantName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          item.adminEmail.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/super-admin/admins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to :" + err.message);
      });
  };

  // Create Modal Show
  const handleCreateClick = () => {
    setShowAddForm(true);
  };
  const handleCloseCreateForm = () => {
    setShowAddForm(false);
  };

  // Delete Modal Show
  const handleDeleteClick = (id) => {
    setAdminId(id);
    setShowDeleteForm(true);
  };
  const handleCloseDeleteForm = () => {
    setShowDeleteForm(false);
  };

  // Update Modal Show
  const handleUplaodClick = (id) => {
    setAdminId(id);
    setShowEditForm(true);
  };
  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");

    if (wrapperElement) {
      if (showAddForm || showEditForm || showDeleteForm) {
        wrapperElement.style.zIndex = "120";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };

  useEffect(() => {
    applySpecialStyles();
  }, [showAddForm], [showEditForm], [showDeleteForm]);

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100"
          >
            <Card.Header className="card-header-Action">
              <h5>Admin Management</h5>
              <Button
                variant="primary btn-animated"
                size="sm"
                className="ms-3"
                id="addRestaurantAdmin"
                onClick={handleCreateClick}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="btn-text " size="lg">
                    Add new
                  </span>
                </span>
              </Button>
            </Card.Header>
            <Card.Body>
              {/* Search function */}
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Restaurant</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="dataTable">
                      {currentItems.map((item) => (
                        <tr key={item._id}>
                          <td>{item.adminName}</td>
                          <td>{item.adminContact ? `0${item.adminContact}` : ''}</td>
                          <td>{item.adminEmail}</td>
                          <td>{item.restaurantName}</td>
                          <td>
                            <Button
                              id="editBtn"
                              size="sm"
                              variant="soft-primary"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleUplaodClick(item._id);
                              }}
                            >
                            Edit
                            </Button>

                            <Button
                              id="deleteBtn"
                              size="sm"
                              variant={"soft-danger"}
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleDeleteClick(item._id);
                              }}
                            >
                            Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <AddModal
        showForm={showAddForm}
        handleCloseForm={handleCloseCreateForm}
        Token={Token}
        fetchData={fetchData}
      />
      <DeleteModal
        showForm={showDeleteForm}
        handleCloseForm={handleCloseDeleteForm}
        Token={Token}
        adminId={adminId}
        fetchData={fetchData}
      />
      <EditFormModal
        showForm={showEditForm}
        handleCloseForm={handleCloseEditForm}
        Token={Token}
        adminId={adminId}
        fetchData={fetchData}
      />
    </>
  );
};

export default RestaurantAdminManagement;
