import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Container,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import defaultImage from "../../../assets/dist/img/default_photo.png";
import useRestaurantPackageServices from "../../../services/restaurantPackageService";


const PackagesDetails = ({
  restaurantsPackageData,
  restaurantContactNumber,
}) => {
  const [restaurantPackagesData, setRestaurantPackagesData] = useState([]);

  const {
    restaurantPackagesData: hookRestaurantPackagesData,
  } = useRestaurantPackageServices();

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([
    ...restaurantPackagesData,
  ]);

  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchTerm) {
      const filterItems = restaurantPackagesData.filter(
        (item) =>
          item.packageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.packagePrice.toString() === searchTerm.toString()
      );
      setFilteredLists(filterItems);
      setCurrentPage(1);
    } else {
      setFilteredLists([...restaurantPackagesData]);
    }
  }, [restaurantPackagesData, searchTerm]);

  useEffect(() => {
    // Show only active packages to users
    if (Array.isArray(restaurantsPackageData) && restaurantsPackageData.length > 0) {
      setRestaurantPackagesData(restaurantsPackageData.filter(pkg => pkg.state === "active"));
    }
  }, [restaurantsPackageData]);

  useEffect(() => {}, [hookRestaurantPackagesData]);

  return (
    <div>
      <Container>
        <div>
          <Row className="text-center">
            {restaurantsPackageData.message === "No packages at the moment" ? (
              <>
                <h6 className="mb-4">No packages available</h6>
                <div className="d-flex align-items-center justify-content-center mb-10">
                  <a
                    href={`https://wa.me/+60${restaurantContactNumber}?text=Hi, I'm interested to dine at your restaurant and I would like to inquire about your promotional packages. Could you please provide me with information regarding those packages?`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button style={{ fontSize: "0.85rem" }}>
                      Click to find out more
                    </Button>
                  </a>
                </div>
              </>
            ) : (
              <>
                {currentItems.length === 0 ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-center fs-7"
                    style={{ height: "200px" }}
                  >
                    <>No Package Offers Available At The Moment</>
                  </div>
                ) : (
                  <>
                    <h5 className="mb-4">Choose your package</h5>
                    {currentItems.map((item) => (
                      <Link
                        key={item._id}
                        to="#"
                        className="d-block col-xl-2 col-sm-4 col-xs-12 mb-5"
                      >
                        <Card className="card-border">
                          {item.packageImage ? (
                            <LightGallery
                              elementClassNames="image-container"
                              speed={500}
                              mode="lg-fade"
                              plugins={[lgZoom, lgThumbnail]}
                              thumbnail={false}
                              subHtmlSelectorRelative={true}
                              defaultCaptionHeight="100%"
                              download={false}
                            >
                              <Card.Img
                                src={item.packageImage}
                                alt="package-image"
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            </LightGallery>
                          ) : (
                            <Card.Img
                              src={defaultImage}
                              alt="default-package"
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </Card>
                        <div className="mb-0 text-muted fw-bold" style={{ fontSize: "0.9rem" }}>{item.packageName}</div>
                        <div className="text-muted" style={{ fontSize: "0.85rem" }}>
                          RM {item.packagePrice}
                        </div>
                        {item.depositRequired && <div>Deposit Required</div>}
                      </Link>
                    ))}

                    <Row>
                      <Col
                        md={{ span: 3, offset: 10 }}
                        xs={{ span: 100, offset: 0 }}
                        className="text-center mt-3"
                      >
                        <Button
                          variant="primary btn-animated"
                          className="btn-icon"
                          disabled={currentPage === 1}
                          onClick={() => paginate(currentPage - 1)}
                        >
                          <span>
                            <i className="ri-arrow-left-s-line" />
                          </span>
                        </Button>
                        <span className="mx-1">{currentPage}</span>
                        <Button
                          variant="primary btn-animated "
                          className="btn-icon"
                          disabled={
                            indexOfLastItem >= restaurantsPackageData.length
                          }
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <span>
                            <i className="ri-arrow-right-s-line" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default PackagesDetails;
