import React, { useEffect, useState } from "react";
import { Col, Form, Row, InputGroup, Button } from "react-bootstrap";
import { motion } from 'framer-motion';
import { FaThumbsUp } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faGifts, faCircleExclamation, faListCheck } from '@fortawesome/free-solid-svg-icons';
import confetti from 'canvas-confetti';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const AccountManagement = () => {
  const accessToken = Cookies.get("token");
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const [clientInfo, setClientInfo] = useState({});

  const [referral, setReferral] = useState("");
  const [referralSuccess, setReferralSuccess] = useState(false);

  const [launchReceiptFile, setLaunchReceiptFile] = useState("");
  const [gotFile, setGotFile] = useState(false);
  const [fileEvent, setFileEvent] = useState(null);
  const [launchReceiptSuccess, setLaunchReceiptSuccess] = useState(false);

  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);

  const [isSendEmail, setIsSendEmail] = useState(false);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    const fetchClientInfo = async () => {
      if (decodedToken) {
        try {
          const response = await fetch(`${backendUri}/api/v0/client/launchDetail/${decodedToken.userId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            });

          if (response.ok) {
            const clientData = await response.json();
            setClientInfo(clientData);
          }
        } catch (error) {
          console.error("Error fetching client information:", error);
        }
      }
    };
  
    fetchClientInfo();
  }, [decodedToken]);

  useEffect(() => {
    if (clientInfo && clientInfo.launchCampaign) {
      if (clientInfo.launchCampaign.referralEmail) {
        setReferral(clientInfo.launchCampaign.referralEmail);
        setReferralSuccess(true);
      }
      if (clientInfo.launchCampaign.receiptFile) {
        setLaunchReceiptFile(clientInfo.launchCampaign.receiptFile);
        setLaunchReceiptSuccess(true);
      }
      if (clientInfo.launchCampaign.surveyFormSubmitted) {
        setIsSurveySubmitted(clientInfo.launchCampaign.surveyFormSubmitted);
      }
      if (clientInfo.launchCampaign.sendEmail) {
        setIsSendEmail(clientInfo.launchCampaign.sendEmail);
      }
    }
  }, [clientInfo]);

  useEffect(() => {
    if (isSendEmail) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    }
  }, [isSendEmail]);

  const handleReferral = async (e) => {
    const newReferral = e.target.value;
    setReferral(newReferral);
    updateReferral(newReferral);
  };

  const updateReferral = (referralEmail) => {
    fetch(`${backendUri}/api/v0/client/referralEmail/${decodedToken.userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ referralEmail: referralEmail }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message === "Eligible") {
          setReferralSuccess(true);
        }
        if (data.message === "Not Eligible") {
          setReferralSuccess(false);
        }
      })
      .catch((err) => {
        console.error("err", err.message);
      });
  };

  useEffect(() => {
    if (gotFile && launchReceiptFile) {
      handleUploadReceipt();
    }
  }, [gotFile, launchReceiptFile]);

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      setLaunchReceiptFile(e.target.files[0]);
      setGotFile(true);
      setFileEvent(e);
    } else {
      setGotFile(false);
      setLaunchReceiptFile("");
    }
  };

  const handleUploadReceipt = () => {
    if (!launchReceiptFile || !gotFile) {
      console.error("Files are missing");
      return;
    }

    const formData = new FormData();
    formData.append("launchReceiptFile", launchReceiptFile);
    formData.append("gotFile", gotFile);

    let isTimedOut = false;
    const timeout = setTimeout(() => {
      isTimedOut = true;
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
    }, 5000);

    fetch(`${backendUri}/api/v0/client/uploadLaunchReceipt/${decodedToken.userId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((data) => {
        if (data.message === "Receipt Uploaded Successfully") {
          setLaunchReceiptSuccess(true);
        } else {
          setLaunchReceiptSuccess(false);
        }
        setGotFile(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error("An error occurred while processing your request.", {
          containerId: "error-container",
        });
      })
  };

  const handleSurveySubmitted = async (e) => {
    const newValue = e.target.checked;
    setIsSurveySubmitted(newValue);

    fetch(`${backendUri}/api/v0/client/surveySubmitted/${decodedToken.userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ isSurveySubmitted: newValue }),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleSendEmail = async () => {
    setIsSendEmail(true);

    fetch(`${backendUri}/api/v0/client/sendEmail/${decodedToken.userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ sendEmail: true }),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.error(err.message);
        setIsSendEmail(false);
      });
  };

  const termsAndConditions = (
    <>
      <p>These are the terms and conditions of this Launch Campaign</p>
      <p>(25 September 2024 to 24 November 2024) Limited-Time Offer!</p>
      <p>
        We're excited to launch the Konfem platform with a special campaign where YOU can earn up to RM10 by completing a few simple tasks!</p>
      <p className="fw-bold mt-2 text-decoration-underline">How to participate:</p>
      <p className="fw-bold">
        <FontAwesomeIcon icon={faListCheck} className="me-1"/>
        Complete the tasks below and unlock your rewards:
      </p>
      <ul>
        <li>
          <span className="fw-bold">Task 1:</span> 
          Refer 1 person to Konfem by providing the email address your referral used to SignUp with Konfem.
        </li>
        <li>
          <span className="fw-bold">Task 2:</span>
          Fill out a quick 3 minutes survey via the Link provided.
        </li>
        <li>
          <span className="fw-bold">Task 3(Optional):</span>
          Make 1 reservation via Konfem and upload your dine-in receipt of that restaurant.
        </li>
      </ul>
      <p className="fw-bold">
        <FontAwesomeIcon icon={faGifts} className="me-1"/>
        Your Rewards:
      </p>
      <ul>
        <li>RM5 DuitNow: Complete Task 1 and Task 2</li>
        <li>RM10 DuitNow or TnG Reload Pin: Complete all three tasks (including Task3) for the extra reward</li>
      </ul>
      <p className="fw-bold">
        <FontAwesomeIcon icon={faCircleExclamation} className="me-1"/>
        Important:
      </p>
      <ul>
        <li>Once you click the "Send" button, no amendments are allowed.</li>
        <li>If you click "Send" after completing only Task1 and Task2, you will be eligible for the RM5 reward, and you will
          not be able to complete Task3 later for the RM10 reward.
        </li>
        <li>So, HURRY make your reservation if you want to double your rewards!!</li>
      </ul>
      <p className="fw-bold">
        <FontAwesomeIcon icon={faTrophy} className="me-1"/>
        How to claim:
      </p>
      <ul>
        <li>Once you've completed your tasks, simply click the "Send" button. We will confirm your reward within 3-5 working days.</li>
      </ul>
    </>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Form>
        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="border border-primary p-2 mt-5 mb-2 fs-7">
                <Form.Label className="fw-bold terms-label">Terms & Conditions</Form.Label>
                <div className="terms-text text-wrap fs-8">
                  {termsAndConditions}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mt-5 mb-5 fs-7">
                <Form.Label>
                  <span className="fw-bold d-block">Referral</span>
                  <span className="fs-8 d-block text-muted">Note: Provide the email of someone you referred and signed up to Konfem</span>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-user fs-7" />
                  </InputGroup.Text>
                  <Form.Control
                    className={`fs-7 ${referralSuccess ? "is-valid" : "is-invalid"}`}
                    id="referral"
                    type="text"
                    value={referral}
                    placeholder="Enter Referral Email"
                    onChange={handleReferral}
                    disabled={referralSuccess}
                  />
                  {referralSuccess ? (
                    <div className="valid-feedback fs-8">Matched!</div>
                  ) : (
                    <div className="invalid-feedback fs-8">Not There Yet!</div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-5 fs-7">
                <Form.Label className="fw-bold">
                  <div className="text-decoration-underline">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeU6iyyZVDDuS2v1CgZaxmlho9Xe-Kf3jH7__ceLgtnlFMzgQ/viewform" target="_blank" rel="noopener noreferrer">
                      Link: Survey Form (3 minutes)
                    </a>
                  </div>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <Form.Check
                      className="mt-0"
                      type="checkbox"
                      id="isSurveySubmitted"
                      checked={isSurveySubmitted}
                      onChange={handleSurveySubmitted}
                      disabled={isSendEmail}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    className={`fs-7 ${isSurveySubmitted ? "is-valid" : "is-invalid"}`}
                    id="surveyForm"
                    type="text"
                    placeholder="(Tick this checkbox after you've submitted this Survey Form)"
                    disabled
                  />
                  {isSurveySubmitted ? (
                    <div className="valid-feedback fs-8">Submitted!</div>
                  ) : (
                    <div className="invalid-feedback fs-8">Not Completed!</div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-5 fs-7" onSubmit={handleUploadReceipt}>
                <Form.Label>
                  <span className="fw-bold d-block">(Optional) Upload 1 Dine-In Receipt from your Reservation via Konfem</span>
                  <span className="fs-8 d-block text-muted">Note: Receipt must have the Restaurant Name & Date of Visit</span>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-image fs-7" />
                  </InputGroup.Text>
                  <Form.Control
                    className={`fs-7 ${launchReceiptSuccess ? "is-valid" : "is-invalid"}`}
                    id="uploadReceipt"
                    type="file"
                    accept="image/*"
                    name="launchReceiptFile"
                    onChange={handleChange}
                    disabled={launchReceiptSuccess || isSendEmail}
                  />
                  {launchReceiptSuccess ? (
                    <div className="valid-feedback fs-8">{`Successfully Uploaded!`}</div>
                  ) : (
                    <div className="invalid-feedback fs-8">No Receipt Uploaded Yet!</div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </motion.div>

        <Row>
          <Col>
            <Button 
              variant="outline-primary" 
              className="fs-7" 
              onClick={handleSendEmail}
              disabled={!referralSuccess || !isSurveySubmitted || isSendEmail}
            >
              Send
            </Button>
          </Col>
        </Row>
        {isSendEmail && (
          <div className="fs-6 text-center py-2">
            <div className="mb-2 d-flex justify-content-center align-items-center">
              <motion.div animate={{ y: [0, -10, 0] }} transition={{ repeat: Infinity, duration: 0.6 }}>
                <FaThumbsUp className="text-primary me-1 fs-5" />
              </motion.div>
              <motion.div animate={{ y: [0, -10, 0] }} transition={{ repeat: Infinity, duration: 0.6 }}>
                <FaThumbsUp className="text-primary me-1 fs-5" />
              </motion.div>
              <motion.div animate={{ y: [0, -10, 0] }} transition={{ repeat: Infinity, duration: 0.6 }}>
                <FaThumbsUp className="text-primary me-1 fs-5" />
              </motion.div>
            </div>
            <div className="px-3">
              <h3 className="text-primary fw-bold">THANK YOU!</h3>
              <p className="mb-1 fs-6">
                🎉 <strong>Congratulations</strong> on completing the tasks! 🎉
              </p>
              <p className="mb-1 fs-6">
                We truly appreciate your participation and support in the Konfem Launch Campaign.
              </p>
              <p className="fs-7 text-muted">
                We will get back to you within <strong>3 to 5 working days</strong> with your reward!
              </p>
            </div>
          </div>
        )}
      </Form>
    </motion.div>
  );
};

export default AccountManagement;