import React from "react";
import { useLocation } from 'react-router-dom';
import RestaurantCardsBody from "../RestaurantCardsBody";


const CategoryRestaurantCards = () => {
    const location = useLocation();
    const { restaurantsInfo, clientInfo } = location.state || { restaurantsInfo: [], clientInfo: {} };
  return (
    <>
      <div className="hk-pg-body p-3 font-monospace">
        {restaurantsInfo.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{ height: "200px" }}
          >
            Uh-oh! No bites here. How about trying something different?
          </div>
        ) : (
          <RestaurantCardsBody restaurantsInfo={restaurantsInfo} clientInfo={clientInfo}/>
        )}
      </div>
    </>
  );
};

export default CategoryRestaurantCards;