import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const TogglePackageState = ({ item, Token, fetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleToggleState = () => {
    console.log(item);
    setIsSubmitting(true);

    let isTimedOut = false;
    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/packages/toggle/${item._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
        } else {
          fetchData(Token);
          toast.success(res.message, {
            containerId: "success-container",
          });
        }
      })
      .catch((err) => {
        console.error("Error toggling package state", err);
        toast.error(err.message, {
          containerId: "error-container",
        });
      })
      .finally(() => {
        if (!isTimedOut) {
          setIsSubmitting(false);
        }
      });
  };

  return (
    <Button
      id="toggleStateBtn"
      size="sm"
      className="btn-rounded"
      style={{
        margin: 5,
        backgroundColor: '#e6f7ff',
        color: '#0d6efd',
        border: 'none',
      }}
      onClick={() => {
        handleToggleState();
      }}
    >
      {item.state === "active" ? "Deactivate" : "Activate"}
    </Button>
  );
};

export default TogglePackageState;