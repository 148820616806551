import React from "react";
import { Box } from "@mui/material";

import CustomerRelationshipManagement from "./CustomerRelationshipManagement";


const CRMPage = () => {
  return (
    <Box className="font-monospace" p={3}>
      <CustomerRelationshipManagement />
    </Box>
  );
};

export default CRMPage;
