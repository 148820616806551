import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useAuth } from "../services/authContext";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useEventServices = () => {
  const [eventInfo, setEventInfo] = useState({});
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [pendingEvents, setPendingEvents] = useState("");
  const [responseUpcomingEvents, setResponseUpcomingEvents] = useState("");
  const [responsePastEvents, setResponsePastEvents] = useState("");
  const accessToken = Cookies.get("token");
  const { user } = useAuth();

  useEffect(() => {
    getEvents();
  }, [accessToken]);

  const getEvents = async () => {
    if (!accessToken) {
      return;
    }

    let fetchEventsAPI;
    try {
      if (user.role === "superAdmin") {
        fetchEventsAPI = `${backendUri}/api/v0/super-admin/events/`;
      } else {
        fetchEventsAPI = `${backendUri}/api/v0/client/events`;
      }
      const response = await fetch(fetchEventsAPI, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setEventsData(responseData);

        if (
          responseData.pastEvents !== undefined &&
          responseData.upcomingEvents !== undefined
        ) {
          const pastEvents = responseData.pastEvents;
          const upcomingEvents = responseData.upcomingEvents;

          setResponseUpcomingEvents(upcomingEvents);
          setResponsePastEvents(pastEvents);
        } else {
          // Handle the case where pastEvents or upcomingEvents are undefined
          setResponseUpcomingEvents([]);
          setResponsePastEvents([]);
        }
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const getEventsByStatus = async (eventStatus) => {
    if (!accessToken || user.role === "client") {
      return;
    }
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/events/get-status/${eventStatus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();

        if (responseData) {
          setPendingEvents(responseData); // Update state with fetched data
        }
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchEventInfo = async (eventId) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/${user.role}/events/${eventId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const eventData = await response.json();
        setEventInfo(eventData);
        return eventData;
      }
    } catch (error) {
      console.error("Error fetching event information:", error);
    }
  };

  const fetchQRCode = async (eventId) => {
    try {
      if (!eventId) {
        return;
      }
      const response = await fetch(
        `${backendUri}/api/v0/client/events/qr/${eventId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const qrCodeUrl = URL.createObjectURL(blob);
        setQrCodeUrl(qrCodeUrl);
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const createEvent = (event, notificationString) => {
    const eventDate = event.eventDate;
    const eventTime = event.eventTime;
    const title = event.title;
    const eventName = event.eventName;
    const selectedPackages = event.packages;
    const remark = event.remark;
    const noOfTable = event.noOfTable;
    const noOfPax = event.noOfPax;
    const restaurantId = event.restaurantId;
    const closingBefore = event.closingBefore;
    const phoneNo = event.phoneNo;
    const timestamp = event.timestamp;
    const reservationMethod = event.reservationMethod;
   
    const eventData = {
      eventDate,
      eventTime,
      title,
      eventName,
      packages: selectedPackages,
      remark,
      noOfTable,
      noOfPax,
      restaurantId,
      closingBefore,
      notificationString,
      timestamp,
      reservationMethod,
    }
    eventData.phoneNo = phoneNo
    
    setIsSubmitting(true);

    fetch(`${backendUri}/api/v0/${user.role}/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(eventData),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
          setIsSubmitting(false);
        } else {
          toast.success(res.message, {
            containerId: "toast-container",
          });
          setSuccess(true);
          setIsSubmitting(false);
          //props.handleCloseForm();
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const deleteEvent = async (eventId) => {
    await fetch(
      `${backendUri}/api/v0/admin/events/${eventId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
          setIsSubmitting(false);
        } else {
          toast.success(res.message, {
            containerId: "toast-container",
          });
          setSuccess(true);
          setIsSubmitting(false);
          //props.handleCloseForm();
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const updateEvent = (event, notificationString) => {
    const status = event.status;
    const eventDate = new Date(event.eventDate);
    const eventTime = new Date(event.eventTime);
    const title = event.title;
    const phoneNo = event.phoneNo;
    const eventName = event.eventName;
    const closingBefore = event.closingBefore;
    const restaurantPackages = event.packages;
    const remark = event.remark;
    const noOfTable = event.noOfTable;
    const noOfPax = event.noOfPax;
    const timestamp = event.timestamp;
    const reservationMethod = event.reservationMethod;

    setIsSubmitting(true);
    fetch(`${backendUri}/api/v0/${user.role}/events/${event.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        status,
        eventDate,
        eventTime,
        title,
        phoneNo,
        eventName,
        closingBefore,
        restaurantPackages,
        remark,
        noOfTable,
        noOfPax,
        notificationString,
        timestamp,
        reservationMethod,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
          setIsSubmitting(false);
        } else {
          toast.success(res.message, {
            containerId: "toast-container",
          });
          setSuccess(true);
          setIsSubmitting(false);
          //props.handleCloseForm();
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const clearIsSubmitting = () => {
    setIsSubmitting(null);
  };
  return {
    fetchEventInfo,
    fetchQRCode,
    createEvent,
    success,
    eventInfo,
    qrCodeUrl,
    eventsData,
    isSubmitting,
    setSuccess,
    updateEvent,
    pendingEvents,
    clearIsSubmitting,
    getEventsByStatus,
    deleteEvent,
    responseUpcomingEvents,
    getEvents,
    responsePastEvents,
  };
};

export default useEventServices;
