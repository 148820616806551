import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from "../../../services/authContext";
import useCampaignService from '../../../services/campaignService';
import launchImage from '../../../assets/developImg/launchCampaign.gif';


const LaunchCampaignBanner = () => {
  const { authenticated, user } = useAuth();

  const { launchCampaignChecked, launchCampaignComplete } = useCampaignService();
  const [showCampaignPopup, setShowCampaignPopup] = useState(false);
  const [showCampaignCompletePopup, setShowCampaignCompletePopup] = useState(false);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (launchCampaignChecked && !launchCampaignComplete) {
      setShowCampaignPopup(true);
      setShowCampaignCompletePopup(false);
    }

    if (!launchCampaignChecked && launchCampaignComplete) {
      setShowCampaignPopup(false);
      setShowCampaignCompletePopup(true);
    }
  }, [launchCampaignChecked, launchCampaignComplete]);

  const isSmallScreen = screenSize < 600;
  const isMediumScreen = screenSize < 1024;

  const carouselImageStyle = {
    width: '100%',
    height: isSmallScreen ? '150px' : isMediumScreen ? '200px' : '250px',
    objectFit: 'contain'
  };

  return (
    <>
      {showCampaignPopup ? (
        <>
          {authenticated && user.role === 'client' ? (
            <Link to="app/profile">
              <img src={launchImage} alt="Launch Campaign" style={carouselImageStyle}/>
            </Link>
          ) : null}
          {authenticated && user.role !== 'client' ? (
              <img src={launchImage} alt="Launch Campaign" style={carouselImageStyle}/>
          ) : null}
          {!authenticated ? (
            <Link to="/auth/">
              <img src={launchImage} alt="Launch Campaign" style={carouselImageStyle}/>
            </Link>
          ) : null}
        </>
      ) : null}
      {/* {showCampaignCompletePopup ? (
        <img src={launchImageComplete} alt="Launch Campaign" style={{ width: '100%', height: '100%' }}/>
      ) : null} */}
    </>
  );
};

export default LaunchCampaignBanner;