import React, { useEffect, useState } from "react";
import { 
  Button,
} from "react-bootstrap";
import * as Icons from "tabler-icons-react";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RestaurantNearMe = ({ lists, defaultClickedNearMe, onUpdate, setLoading }) => {
  const [restaurantLists, setRestaurantLists] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [isClickedNearMe, setIsClickedNearMe] = useState(false); 
  const [userlatitude, setUserLatitude] = useState(null);
  const [userlongitude, setUserLongitude] = useState(null);

  useEffect(() => {
    if (lists.length > 0) {
      setRestaurantLists(lists);
    }
    setIsClickedNearMe(defaultClickedNearMe);
  }, [lists]);

  useEffect(() => {
    onUpdate({ isClickedNearMe, filteredRestaurants });
  }, [filteredRestaurants, isClickedNearMe]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isClickedNearMe) {
        onUpdate({ isClickedNearMe, restaurantLists });
        return;
      }

      // Get user geolocation
      await getUserGeolocation();

      // Update all restaurants latitude and longitude
      //   await Promise.all(restaurantLists.map(async (restaurant) => {
      //     await restaurantLatLng(restaurant._id);
      //   }));

      // Calculate distance between user and restaurant - append to filteredRestaurants
      filteringRestaurants().then((filteredRestaurants) => {
        setFilteredRestaurants([...filteredRestaurants]);
      });
    };

    fetchData();
  }, [userlatitude, userlongitude, isClickedNearMe]);

  const handleNearMe = async () => {
    const newClickedNearMe = !isClickedNearMe;
    setIsClickedNearMe(newClickedNearMe);
    setLoading(true);
  };

  const getUserGeolocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLatitude(position.coords.latitude);
            setUserLongitude(position.coords.longitude);
            resolve();
          },
          (error) => {
            console.error('Error getting user location:', error);
            setIsClickedNearMe(false);
            reject(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setIsClickedNearMe(false);
        reject('Geolocation is not supported by this browser.');
      }
    });
  };

  const restaurantLatLng = async (id) => {
    // Parse restaurant latitude and longitude 66cb18837513f6ccd3f91b39
    await fetch(`${backendUri}/api/v0/public/restaurants/updateRestaurantLocation/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  const filteringRestaurants = async () => {
    const promises = restaurantLists.map(async (restaurant) => {
      if (restaurant.restaurantLocation && restaurant.restaurantLocation.lat && restaurant.restaurantLocation.lng) {
        const distance = await calculateDistanceAsync(
          userlatitude,
          userlongitude,
          restaurant.restaurantLocation.lat,
          restaurant.restaurantLocation.lng,
        );
        return { ...restaurant, distance };
      }
      return null;
    });
  
    const results = await Promise.all(promises);
    const filteredByDistance = results.filter((restaurant) => restaurant && restaurant.distance <= 10);

    // Sort the filtered restaurants based on distance
    filteredByDistance.sort((a, b) => a.distance - b.distance);

    return filteredByDistance;
  };

  // Asynchronous distance calculation
  const calculateDistanceAsync = (lat1, lon1, lat2, lon2) => {
    return new Promise((resolve) => {
      const distance = calculateDistance(lat1, lon1, lat2, lon2);
      resolve(distance);
    });
  };

  // Haversine formula to calculate distance between two points
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    const roundedDistance = d.toFixed(2);
    return roundedDistance;
  }
  
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  return (
    <>
      <Button className="btn btn-sm me-2 shadow" variant="outline-primary" 
        style={{ display: "flex", alignItems: "center", padding: "0.2rem", backgroundColor: isClickedNearMe ? '#ed9c00' : '#f0f0f0',}}
        onClick={() => handleNearMe()}
      >
        <Icons.AdjustmentsHorizontal />
        <span className={isClickedNearMe ? 'text-black' : 'text-primary'} style={{ fontSize: "0.7rem" }}>Near Me(10km)</span>
      </Button>
    </>
  );
};

export default RestaurantNearMe;