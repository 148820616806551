import React from "react";
import { Box } from "@mui/material";

import CustomerEngagementAnalytics from "./CustomerEngagementAnalytics";


const AnalyticsPage = () => {
  return (
    <Box className="font-monospace" p={3}>
      <CustomerEngagementAnalytics />
    </Box>
  );
};

export default AnalyticsPage;
