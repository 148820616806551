import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  TabContent,
} from "react-bootstrap";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import Clients from './ClientAnalytics';
import Restaurants from './RestaurantAnalytics';

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

// Register the necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const SuperAdminAnalytics = () => {

  return (
    <>
      <TabContent>
        <Card className="card-border mb-0 h-100">
          <Card.Header className="card-header-Action">
            <h5>SuperAdmin Analytics</h5>
          </Card.Header>
        </Card>
      </TabContent>
      <Router>
        <Container fluid className="bg-light">
          <Row>
            <Col md={2} className="bg-light sidebar" style={{ width: '10%' }}>
              <Nav defaultActiveKey="/app/ourdashboard/clientAnalysis"  className="flex-column">
                <Nav.Link as={Link} to="/app/ourdashboard/clientsAnalysis">Clients</Nav.Link>
                <Nav.Link as={Link} to="/app/ourdashboard/restaurantsAnalysis">Restaurants</Nav.Link>
              </Nav>
            </Col>
            <Col md={10} className="main-content">
              <Row>
                <Switch>
                  <Route path="/app/ourdashboard/clientsAnalysis" component={Clients} />
                  <Route path="/app/ourdashboard/restaurantsAnalysis" component={Restaurants} />
                  <Redirect from="/" to="/app/ourdashboard/clientsAnalysis" />
                </Switch>
              </Row>
            </Col>
          </Row>
        </Container>
      </Router>
    </>
  );
};

export default SuperAdminAnalytics;
