import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useAuth } from "../services/authContext";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useRestaurantServices = () => {
  const [token, setToken] = useState(null);
  const [restaurantsData, setRestaurantsData] = useState("");
  const [restaurantInfo, setRestaurantInfo] = useState("");
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchAccessToken = async () => {
      const accessToken = Cookies.get("token");
      setToken(accessToken);
    };

    fetchAccessToken();
  }, []);

  useEffect(() => {
    getRestaurants();
  }, [token]);

  const getRestaurants = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      let fetchRestaurantsAPI;
      user.role === "superAdmin" && token
        ? (fetchRestaurantsAPI = `${backendUri}/api/v0/super-admin/restaurants/`)
        : (fetchRestaurantsAPI = `${backendUri}/api/v0/public/restaurants`);

      const response = await fetch(fetchRestaurantsAPI, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const responseData = await response.json();
        setRestaurantsData(responseData);
      }
    } catch (error) {
      console.error("Error fetching restaurants:", error);
    }
  };

  const fetchRestaurantInfo = async (restaurantId) => {
    try {
      if(user.role !== "admin" && !restaurantId){
        return
      }
      const headers = {
        "Content-Type": "application/json",
      };

      let fetchRestaurantInfo;

      if(user.role === "superAdmin"){
        fetchRestaurantInfo = `${backendUri}/api/v0/super-admin/restaurants/${restaurantId}`
      }else if(user.role === "admin" && !restaurantId){
        fetchRestaurantInfo = `${backendUri}/api/v0/admin/restaurants`
      }else {
        fetchRestaurantInfo = `${backendUri}/api/v0/public/restaurants/${restaurantId}`
      }
     
      if (user.role === "superAdmin" || user.role === "admin") {
        headers.Authorization = `Bearer ${Cookies.get("token")}`;
      }
      const response = await fetch(fetchRestaurantInfo, {
        method: "GET",
        headers,
      });

      if (response.ok) {
        const restaurantData = await response.json();

        setRestaurantInfo(restaurantData);
      }
    } catch (error) {
      console.error("Error fetching restaurant information:", error);
    }
  };

  const manageRestaurant = (restaurant) => {
    const restaurantName = restaurant.restaurantName;
    const restaurantPlacesId = restaurant.restaurantPlacesId;
    const addressLine1 = restaurant.addressLine1;
    const addressLine2 = restaurant.addressLine2;
    const city = restaurant.city;
    const state = restaurant.state;
    const zipCode = restaurant.zipCode;
    const country = restaurant.country;
    const restaurantContact = restaurant.restaurantContact;
    const restaurantContactLandline = restaurant.restaurantContactLandline;
    const addressLink = restaurant.addressLink;
    const restaurantLocation = restaurant.restaurantLocation;
    const paymentDetails = restaurant.paymentDetails;
    const refundPolicy = restaurant.refundPolicy;
    const tags = restaurant.tags;
    const cuisineTags = restaurant.cuisineTags;
    const foodTags = restaurant.foodTags;
    const amenitiesTags = restaurant.amenitiesTags;
    const description = restaurant.description;
    const businessHours = restaurant.businessHours;
    const reservationsMethod = restaurant.reservationsMethod;
    const isBoarded = restaurant.isBoarded;
    const onBoardedStartDate = restaurant.onBoardedStartDate;
    const pricingModel = restaurant.pricingModel;
    const gotFile = restaurant.gotFile;
    const restaurantLogo = restaurant.restaurantLogo;
    const ratings = restaurant.ratings;
    const reviews = restaurant.reviews;
    const photoReferences = restaurant.photoReferences;
    const photoFiles = restaurant.photoFiles;
    const restaurantPromo = restaurant.restaurantPromo;
    const featureAnalyticsAndCRM = restaurant.featureAnalyticsAndCRM;

    const formData = new FormData();
    formData.append("restaurantName", restaurantName);
    formData.append("restaurantPlacesId", restaurantPlacesId);
    formData.append("addressLine1", addressLine1);
    formData.append("addressLine2", addressLine2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", zipCode);
    formData.append("country", country);
    formData.append("addressLink", addressLink);
    formData.append("restaurantLocation", JSON.stringify(restaurantLocation));
    formData.append("restaurantContact", restaurantContact);
    formData.append("restaurantContactLandline", restaurantContactLandline);
    formData.append("paymentDetails", paymentDetails);
    formData.append("refundPolicy", refundPolicy);
    formData.append("tags", tags);
    formData.append("cuisineTags", JSON.stringify(cuisineTags));
    formData.append("foodTags", JSON.stringify(foodTags));
    formData.append("amenitiesTags", JSON.stringify(amenitiesTags));
    formData.append("description", description);
    formData.append("gotFile", gotFile);
    formData.append("file", restaurantLogo);
    formData.append("reservationsMethod", reservationsMethod);
    formData.append("isBoarded", isBoarded);
    formData.append("onBoardedStartDate", onBoardedStartDate);
    formData.append("pricingModel", pricingModel);
    formData.append("ratings", ratings);
    formData.append("photoReferences", JSON.stringify(photoReferences));
    formData.append("photoFiles", JSON.stringify(photoFiles));
    formData.append("restaurantPromo", restaurantPromo);
    formData.append("featureAnalyticsAndCRM", featureAnalyticsAndCRM);
    if (reviews) {
      const reviewsJson = JSON.stringify(reviews);
      formData.append("reviews", reviewsJson);
    }
    if (businessHours) {
      const businessHoursJson = JSON.stringify(businessHours);
      formData.append("businessHours", businessHoursJson);
    }

    let apiUrl  = `${backendUri}/api/v0/admin/restaurants`;
    if(user.role === "superAdmin"){
      if (restaurant.requestType === "POST") {
        apiUrl = `${backendUri}/api/v0/super-admin/restaurants`;
      } else {
        apiUrl = `${backendUri}/api/v0/super-admin/restaurants/${restaurant.restaurantId}`;
      }
    }

    fetch(apiUrl, {
      method: restaurant.requestType,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "toast-container",
          });
          setSuccess(false);
        } else {
          toast.success(res.message, {
            containerId: "toast-container",
          });
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.error("Fetch API fail");
        setSuccess(false);
      });
  };

  const clearSuccess = () => {
    setSuccess(null);
  };

  const clearIsSubmitting = () => {
    setIsSubmitting(null);
  };

  return {
    restaurantsData,
    manageRestaurant,
    restaurantInfo,
    fetchRestaurantInfo,
    success,
    clearSuccess,
    clearIsSubmitting,
    isSubmitting,
    getRestaurants
  };
};

export default useRestaurantServices;
