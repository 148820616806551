import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Container, Nav,  Tab } from 'react-bootstrap';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import ClientInfo from './clientInfo';
import AccountManagement from './accountManagement';
import LaunchCampaign from './launchCampaign';
import useCampaignService from '../../services/campaignService';


const tabVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 20 },
};

const NavTabsCustom = styled(Nav)`
  .nav-link {
    position: relative;
    padding: 10px 15px;
    color: #000;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0;
    transition: background-color 0.3s, color 0.3s;
  }

  .nav-link.active {
    color: #FFAE42;
    background-color: #000;
    border-color: #000;
  }

  .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
    transform: scaleX(0);
    transition: transform 0.3s;
  }

  .nav-link.active::after {
    background-color: #000;
    transform: scaleX(1);
  }
`;

const typewriterVariants = {
  hidden: { opacity: 0 },
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.1,
    },
  }),
};

const ClientProfile = () => {
  const { launchCampaignChecked, launchCampaignComplete } = useCampaignService();
  const [isLaunchCampaignChecked, setIsLaunchCampaignChecked] = useState(false);
  const [isLaunchCampaignComplete, setIsLaunchCampaignComplete] = useState(false);

  useEffect(() => {
    setIsLaunchCampaignChecked(launchCampaignChecked);
    setIsLaunchCampaignComplete(launchCampaignComplete);
  }, [launchCampaignChecked, launchCampaignComplete]);

  const title = "FOODIE PROFILE";
  return (
    <Container>
      <motion.div
        className='hk-pg-header pt-7 pb-4 font-monospace'
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className='pg-title' style={{ display: 'flex' }}>
          {title.split("").map((char, index) => (
            <motion.span
              key={index}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={typewriterVariants}
              style={{ display: 'inline-block' }}
            >
              {char === " " ? "\u00A0" : char}
            </motion.span>
          ))}
        </h1>
      </motion.div>
      <div className='hk-pg-body font-monospace fs-7'>
        <Tab.Container defaultActiveKey="tab1">
          <Row className='edit-profile-wrap'>
            <Col xs={12}>
              <NavTabsCustom variant="tabs" className="d-flex flex-column flex-md-row">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">
                    <span className="nav-link-text fw-bold">Client Profile</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">
                    <span className="nav-link-text fw-bold">Account Management</span>
                  </Nav.Link>
                </Nav.Item>
                { isLaunchCampaignChecked && !isLaunchCampaignComplete && (
                  <Nav.Item>
                    <Nav.Link eventKey="tab3">
                      <span className="nav-link-text fw-bold">
                        <i className="bi bi-stars"></i> Launch Campaign
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}
              </NavTabsCustom>
            </Col>
            <Col xs={12}>
              <Tab.Content>
                <Tab.Pane eventKey="tab1">
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={tabVariants}
                    transition={{ duration: 0.5 }}
                  >
                    <ClientInfo />
                  </motion.div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={tabVariants}
                    transition={{ duration: 0.5 }}
                  >
                    <AccountManagement />
                  </motion.div>
                </Tab.Pane>
                <Tab.Pane eventKey="tab3">
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={tabVariants}
                    transition={{ duration: 0.5 }}
                  >
                    { isLaunchCampaignChecked && !isLaunchCampaignComplete && (
                      <LaunchCampaign />
                    )}
                  </motion.div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  );
};

export default ClientProfile;