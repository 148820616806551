export const cuisineOptions = [
  "American", 
  "Asian", 
  "Cakes&Bakery", 
  "Chinese",
  "Desserts", 
  "European", 
  "Fast Food", 
  "Filipino",
  "French", 
  "Fusion", 
  "German", 
  "Indian",
  "Indonesian", 
  "Italian", 
  "Japanese", 
  "Korean",
  "Malaysian", 
  "Mediterranean", 
  "Mexican", 
  "Middle Eastern",
  "Nyonya", 
  "Spanish", 
  "Singaporean", 
  "Thai",
  "Turkish", 
  "Vegetarian", 
  "Vietnamese", 
  "Western",
];
  
export const foodOptions = [
  "Nasi Lemak", 
  "Cendol", 
  "Satay", 
  "Gorengs", 
  "Wantan Mee", 
  "Bak Kut Teh", 
  "Char Kway Teow", 
  "Durian", 
  "Fried Noodle", 
  "Ice Kacang", 
  "Roti Canai", 
  "Laksa", 
  "Nasi Kandar", 
  "Nasi Kerabu",
  "Chicken Chop", 
  "Pork Chop", 
  "Lamb Chop", 
  "Fish & Chips", 
  "Burgers", 
  "Pasta", 
  "Pizza", 
  "Grills",
  "Deep Fried", 
  "Salads", 
  "Fries", 
  "Sandwiches", 
  "Steak", 
  "Sushi", 
  "Sashimi", 
  "Tempura", 
  "Ramen",
  "Udon", 
  "Omakase", 
  "Yakiniku", 
  "Sukiyaki", 
  "Wagyu", 
  "Iberico", 
  "Kimchi", 
  "Bibimbap", 
  "KBBQ",
  "Jiggae", 
  "Miso",
  "Jajangmyeon", 
  "Tteokbokki", 
  "Samgyetang", 
  "Galbi", 
  "Bulgogi", 
  "Escargot", 
  "Foie Gras", 
  "Caviar", 
  "Oysters",
  "Croque", 
  "Confit", 
  "Bourguignon", 
  "Dim Sum", 
  "Peking Duck", 
  "Hot Pot", 
  "Sichuan", 
  "Fried Rice", 
  "Dumplings",
  "Curry", 
  "Tom Yam", 
  "Pad Thai", 
  "Steam dish", 
  "Seafood", 
  "Paprik", 
  "Glass Noodle", 
  "Tofu", 
  "Briyani", 
  "Tandoori",
  "Mutton", 
  "Banana Leaf", 
  "Shawerma"
];

export const amenitiesOptions = [  
  "Wifi", 
  "Public Parking",
  "Private Parking", 
  "Waiting Area", 
  "Kids Play Area",
  "Air Conditioning", 
  "Wheelchair Accessible", 
  "Non Smoking", 
  "Outdoor Seating", 
  "Private Dining", 
  "Pet Friendly", 
  "Live Band", 
  "Gluten Free", 
  "Vegan Options", 
  "Vegetarian Options", 
  "Halal", 
  "Muslim Friendly",
];