import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Rating } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';


const AddReviews = ({ user, open, handleClose, handleSubmit, newReview, setNewReview, handleChange, handleRatingChange }) => {
  useEffect(() => {
    if (open) {
      setNewReview({ author_name: user.name, rating: 0, text: '' });
    }
  }, [open, user]);

  return (
    <Modal show={open} onHide={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Roboto, sans-serif', fontSize: '14px' }}>
      <Modal.Header closeButton>
        <h5 className="modal-title font-monospace">Thank You, Boss!</h5>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="review-form font-monospace">
          <Form.Group controlId="author_name" className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="author_name"
              defaultValue={user.name}
              readOnly
              required
              autoFocus
              style={{
                borderRadius: '0.25rem',
                border: '1px solid #ced4da',
                padding: '0.35rem',
                fontSize: '0.75rem',
                marginBottom: '1rem',
              }}
            />
          </Form.Group>
          <Form.Group controlId="rating" className="mb-3">
            <Form.Label>Ratings</Form.Label>
            <Rating
              name="rating"
              value={newReview.rating}
              onChange={handleRatingChange}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}
              icon={<Star fontSize="inherit" style={{ color: '#ed9c00' }} />}
              emptyIcon={<StarBorder fontSize="inherit" style={{ color: '#ed9c00' }} />}
            />
          </Form.Group>
          <Form.Group controlId="text" className="mb-3">
            <Form.Label>Review</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="text"
              value={newReview.text}
              onChange={handleChange}
              required
              style={{
                fontSize: '0.75rem',
              }}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="btn btn-outline-primary mt-3 w-100">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddReviews;