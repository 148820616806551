import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Cookies from "js-cookie";

import useCampaignService from '../../services/campaignService';
  
  
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const SuperAdminDashboard = () => {
  const accessToken = Cookies.get("token");
  const { launchCampaignChecked, launchCampaignComplete } = useCampaignService();
  const [isLaunchCampaignChecked, setIsLaunchCampaignChecked] = useState(false);
  const [isLaunchCampaignComplete, setIsLaunchCampaignComplete] = useState(false);

  useEffect(() => {
    setIsLaunchCampaignChecked(launchCampaignChecked);
    setIsLaunchCampaignComplete(launchCampaignComplete);
  }, [launchCampaignChecked, launchCampaignComplete]);

  const updateLaunchCampaignDetails = (launchCampaign, completeLaunchCampaign) => {
    fetch(`${backendUri}/api/v0/super-admin/campaigns`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        launchCampaign: launchCampaign, 
        completeLaunchCampaign: completeLaunchCampaign }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleLaunchCampaignChecked = async () => {
    console.log("launch campaign complete: ", isLaunchCampaignComplete);
    const newValue = !isLaunchCampaignChecked;
    setIsLaunchCampaignChecked(newValue);

    updateLaunchCampaignDetails(newValue, isLaunchCampaignComplete);
  };

  const handleLaunchCampaignComplete = async () => {
    console.log("launch campaign:", isLaunchCampaignChecked);
    const newValue = !isLaunchCampaignComplete;
    setIsLaunchCampaignComplete(newValue);

    updateLaunchCampaignDetails(isLaunchCampaignChecked, newValue);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card className="card-border mb-0 h-100">
              <Card.Header className="card-header-Action">
                <h5>SuperAdmin Dashboard</h5>
              </Card.Header>
            </Card>
          </Col>
        </Row>
        <Row className="ms-3 mt-3">
          <label className="font-monospace fs-7">
            <input
              type="checkbox"
              checked={isLaunchCampaignChecked}
              onChange={handleLaunchCampaignChecked}
            />
            <span className="ms-3">Enable Launch Campaign?</span>
          </label>
        </Row>
        <Row className="ms-3 mt-3">
          <label className="font-monospace fs-7">
            <input
              type="checkbox"
              checked={isLaunchCampaignComplete}
              onChange={handleLaunchCampaignComplete}
            />
            <span className="ms-3">Complete Launch Campaign?</span>
          </label>
        </Row>
      </Container>
    </>
  );
};

export default SuperAdminDashboard;
  