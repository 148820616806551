import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { groupBy } from 'lodash';

import useRestaurantServices from "../../services/restaurantService";
import useEventServices from "../../services/eventService";
import useRestaurantCRMService from "../../services/restaurantCRMService";


const CustomerRelationshipManagement = () => {
  const { restaurantInfo, fetchRestaurantInfo, } = useRestaurantServices();
  const { eventsData } = useEventServices();
  const { restaurantCRMInfo, getRestaurantCRMByAdmin, updateCRM, createCRM } = useRestaurantCRMService();
  const [restaurantId, setRestaurantId] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [remarks, setRemarks] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [sortColumn, setSortColumn] = React.useState('eventDate');
  
  useEffect(() => {
    fetchRestaurantInfo();
  }, []);

  useEffect(() => {
    if (restaurantInfo) {
      setRestaurantId(restaurantInfo._id);
      getRestaurantCRMByAdmin(restaurantInfo._id);
    }
  }, [restaurantInfo]);

  useEffect(() => {
    if (Array.isArray(eventsData) && eventsData.length > 0 && restaurantId) {
      const filtered = eventsData.filter(event => event.restaurantId === restaurantId);
      setFilteredEvents(filtered);
    }
  }, [eventsData, restaurantId]);

  // Group events by client - if clientId is null, group under "manualEntries"
  const groupByClientId = (events) => {
    return groupBy(events, event => (event.clientId?._id && event.clientId?.clientName) || 'manualEntries');
  };
  const groupedEvents = groupByClientId(filteredEvents);

  // Filter clients based on search query
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredClients = Object.keys(groupedEvents)
    .filter(clientId => {
      const client = groupedEvents[clientId][0].clientId;
      return (
        (client && client.clientName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (clientId === "manualEntries" && "Manual Entries".toLowerCase().includes(searchQuery.toLowerCase()))
      );
    })
    .sort((a, b) => {
      // First, keep "manualEntries" at the top
      if (a === "manualEntries") return -1;
      if (b === "manualEntries") return 1;

      // Sort other clients by their most recent reservation date
      const mostRecentDateA = new Date(
        Math.max(...groupedEvents[a].map(event => new Date(event.eventDate)))
      );
      const mostRecentDateB = new Date(
        Math.max(...groupedEvents[b].map(event => new Date(event.eventDate)))
      );

      // Compare dates in descending order (most recent first)
      return mostRecentDateB - mostRecentDateA;
    });

  const selectedClientEvents = selectedClientId ? groupedEvents[selectedClientId] : [];
  const selectedClient = selectedClientEvents.length > 0 ? selectedClientEvents[0].clientId : null;

  // Get all events of selected client
  const handleClientClick = (clientId) => {
    setSelectedClientId(clientId);
 
    // Get CRM info for selected client
    if (clientId !== "manualEntries") {
      if (restaurantCRMInfo && restaurantCRMInfo.length > 0) {
        const selectedClientCRM = restaurantCRMInfo.find(crm => crm.clientId === clientId);
        setRemarks(prevRemarks => ({
          ...prevRemarks,
          [clientId]: selectedClientCRM?.remark || ""
        }));
      }
    }
  };

  // Handle remark change
  const handleRemarkChange = (event) => {
    const { value } = event.target;
    setRemarks(prevRemarks => ({
      ...prevRemarks,
      [selectedClientId]: value
    }));
  };

  // Save remark to database
  const handleSaveRemark = (event) => { 
    event.preventDefault();

    if (selectedClient) {
      const remark = remarks[selectedClientId];
      if (restaurantCRMInfo && restaurantCRMInfo.length > 0) {
        const selectedClientCRM = restaurantCRMInfo.find(crm => crm.clientId === selectedClientId);
        if (selectedClientCRM) {
          updateCRM(selectedClientCRM._id, remark);
        } else {
          const submitData = {
            restaurantId,
            selectedClientId,
            remark,
          }
          createCRM(submitData);
        }
      } else {
        const submitData = {
          restaurantId,
          selectedClientId,
          remark,
        }
        createCRM(submitData);
      }
    }
  };

  // Sort Events by name or by date
  const handleSortClick = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortEventsByColumn = (events, column, order) => {
    return [...events].sort((a, b) => {
      if (column === 'title') {
        // Sort alphabetically by title
        return order === 'asc'
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      } else if (column === 'eventDate') {
        // Sort by date
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

  const sortedSelectedClientEvents = sortEventsByColumn(selectedClientEvents, sortColumn, sortOrder);
  const sortedManualEntriesEvents = groupedEvents["manualEntries"]
    ? sortEventsByColumn(groupedEvents["manualEntries"], sortColumn, sortOrder)
    : [];

  return (
    <>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{
          mt: 2,
          mb: 4,
          color: "linear-gradient(45deg, #1F487E, #333333)",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <PeopleAltIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        <Box component="span">
          Customer Relationship Management
        </Box>
      </Typography>
      
      <Grid container spacing={3}>
        {/* Customer List */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ maxHeight: 400, overflow: 'auto', padding: 2, backgroundColor: '#f5f5f5', boxShadow: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, color: '#333', fontWeight: 'bold' }}>
              Customers
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search customers"
              value={searchQuery}
              onChange={handleSearchChange}
              size="small"
              sx={{ mb: 2, borderColor: "#ed9c00" }}
            />

            <List sx={{ padding: 0 }}>
              {filteredClients.length > 0 ? (
                filteredClients.map(clientId => {
                  const client = groupedEvents[clientId][0].clientId;
                  return (
                    <ListItem 
                      component="button"
                      key={clientId} 
                      onClick={() => handleClientClick(clientId)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: 2,
                        padding: 1.5,
                        marginBottom: 1.5,
                        '&:hover': {
                          backgroundColor: "#ed9c00",
                          color: '#fff',
                        },
                        transition: 'background-color 0.2s ease-in-out',
                      }}
                    >
                      <Avatar sx={{ width: 32, height: 32, marginRight: 2 }}>
                        {client?.clientName ? client.clientName.charAt(0).toUpperCase() : 'M'}
                      </Avatar>
                      <ListItemText 
                        primary={client?.clientName || 'Manual Entries'} 
                        sx={{
                          color: '#333', 
                          fontWeight: client ? 'medium' : 'light', 
                        }} 
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 2 }}>
                  No client found
                </Typography>
              )}
            </List>
          </Paper>
        </Grid>

        {/* Customer Details Card*/}
        <Grid item xs={12} md={9}>
          <Card sx={{ padding: 2, backgroundColor: '#f9f9f9', boxShadow: 4, borderRadius: 2, borderTop: `4px solid #ed9c00` }}>
            <CardContent>
              {selectedClient ? (
                <>
                  <Grid container spacing={3}>
                    {/* Client Name */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <AccountCircleIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedClient?.clientName || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>
                          Customer Name
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* Contact */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <PhoneIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedClient?.clientContact || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>
                          Contact
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <EmailIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333", wordBreak: "break-all"  }}>
                            {selectedClient?.clientEmail || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>
                          Email
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* Total Reservations */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <GroupIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedClientEvents.length}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>
                          Total Reservations Made
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* Remark by Restaurant */}
                  <TextField
                    label="Remark by Restaurant"
                    value={remarks[selectedClientId] || ""}
                    onChange={handleRemarkChange}
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={handleSaveRemark}
                  >
                    Save Remark
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                    Manual Reservation Entries
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <GroupIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {sortedManualEntriesEvents.length}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>
                          Total Reservations Made
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}

              <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2, boxShadow: 3 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {selectedClient ? (
                        <>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>
                            <TableSortLabel
                              active
                              direction={sortOrder}
                              onClick={() => handleSortClick('eventDate')}
                              sx={{
                                color: '#fff',
                                '&.Mui-active': {
                                  color: '#fff',
                                },
                                '.MuiTableSortLabel-icon': {
                                  color: '#fff !important',
                                },
                              }}
                            >
                              Reservation Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Reservation Time</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>No. of Pax</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Remark by Customer</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>
                            <TableSortLabel
                              active
                              direction={sortOrder}
                              onClick={() => handleSortClick('title')}
                              sx={{
                                color: '#fff',
                                '&.Mui-active': {
                                  color: '#fff',
                                },
                                '.MuiTableSortLabel-icon': {
                                  color: '#fff !important',
                                },
                              }}
                            >
                              Reservation Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>
                            <TableSortLabel
                              active
                              direction={sortOrder}
                              onClick={() => handleSortClick('eventDate')}
                              sx={{
                                color: '#fff',
                                '&.Mui-active': {
                                  color: '#fff',
                                },
                                '.MuiTableSortLabel-icon': {
                                  color: '#fff !important',
                                },
                              }}
                            >
                              Reservation Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Reservation Time</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>No. of Pax</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Remark by Customer</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedClient ? (
                      sortedSelectedClientEvents.map(event => (
                        <TableRow key={event._id} hover>
                          <TableCell>{new Date(event.eventDate).toLocaleDateString()}</TableCell>
                          <TableCell>{event.eventTime}</TableCell>
                          <TableCell>{event.noOfPax || ""}</TableCell>
                          <TableCell>{event.remark || ""}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      sortedManualEntriesEvents.map((manualEntriesEvent) => (
                        <TableRow key={manualEntriesEvent._id} hover>
                          <TableCell>{manualEntriesEvent.title}</TableCell>
                          <TableCell>{new Date(manualEntriesEvent.eventDate).toLocaleDateString()}</TableCell>
                          <TableCell>{manualEntriesEvent.eventTime}</TableCell>
                          <TableCell>{manualEntriesEvent.noOfPax || ""}</TableCell>
                          <TableCell>{manualEntriesEvent.remark || ""}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerRelationshipManagement;
