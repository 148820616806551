import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip as MuiTooltip,
} from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import { ThumbUp, Share, Visibility, InfoOutlined } from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import moment from "moment";
import { groupBy } from 'lodash';

import useRestaurantServices from "../../services/restaurantService";
import useEventServices from "../../services/eventService";

const dataColors = ["#ED9C00", "#1F487E", "#FF6F61"];
const totalDataColors = ["#EC4E20", "#5A189A"];
const CustomerEngagementAnalytics = () => {
  const { restaurantInfo, fetchRestaurantInfo, } = useRestaurantServices();
  const { eventsData } = useEventServices();
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantClicks, setRestaurantClicks] = useState([]);
  const [restaurantShares, setRestaurantShares] = useState([]);
  const [restaurantUrlVisits, setRestaurantUrlVisits] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [timeFilter, setTimeFilter] = useState("daily");
  const [combinedData, setCombinedData] = useState([]);
  const [reservationPercentageData, setReservationPercentageData] = useState(0);
  const [reservationTypesData, setReservationTypesData] = useState([]);
  
  useEffect(() => {
    fetchRestaurantInfo();
  }, []);

  useEffect(() => {
    if (restaurantInfo) {
      setRestaurantId(restaurantInfo._id);
      setRestaurantClicks(restaurantInfo.restaurantClicks);
      setRestaurantShares(restaurantInfo.restaurantShareClicks);
      setRestaurantUrlVisits(restaurantInfo.restaurantUrlVisits);
    }
  }, [restaurantInfo]);

  useEffect(() => {
    if (Array.isArray(eventsData) && eventsData.length > 0 && restaurantId) {
      const filtered = eventsData.filter(event => event.restaurantId === restaurantId);
      setFilteredEvents(filtered);

      // Group events by client - if clientId is null, group under "manualEntries"
      const groupByClientId = (events) => {
        return groupBy(events, event => event.clientId?._id || 'manualEntries');
      };
      const grouped = groupByClientId(filtered);

      // Count manual and platform reservations
      const manualCount = grouped['manualEntries'] ? grouped['manualEntries'].length : 0;
      const platformCount = Object.keys(grouped).reduce((count, key) => {
        if (key !== 'manualEntries') {
          count += grouped[key].length;
        }
        return count;
      }, 0);

      setReservationTypesData([
        { name: 'Manual Reservations', value: manualCount },
        { name: 'Platform Reservations', value: platformCount }
      ]);

      // Calculate reservation percentage
      const totalReservationsCount = platformCount;  
      const totalRestaurantClicks = restaurantClicks.length + restaurantUrlVisits.length; 

      const reservationPercentage = totalRestaurantClicks
        ? (totalReservationsCount / totalRestaurantClicks) * 100
        : 0;
      setReservationPercentageData(reservationPercentage);
    } else {
      setReservationTypesData([
        { name: 'Manual Reservations', value: 0 },
        { name: 'Platform Reservations', value: 0 }
      ]);
    }
  }, [eventsData, restaurantId, restaurantClicks]);

  // Process combined data based on time filter
  useEffect(() => {
    const processData = (timestamps, filter) => {
      const format =
        filter === 'daily'
          ? 'YYYY-MM-DD'
          : filter === 'weekly'
            ? 'YYYY-[W]WW'
            : 'YYYY-MM';

      const grouped = timestamps.reduce((acc, timestamp) => {
        const key = moment(timestamp).format(format);

        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key] += 1;
        return acc;
      }, {});

      return grouped;
    };

    // Process clicks and shares data separately
    const clicksGrouped = processData(restaurantClicks.map(click => click.timestamp), timeFilter);
    const sharesGrouped = processData(restaurantShares.map(share => share.timestamp), timeFilter);
    const visitsGrouped = processData(restaurantUrlVisits.map(visit => visit.timestamp), timeFilter);

    // Combine both datasets into a single array
    const combined = Object.keys({ ...clicksGrouped, ...sharesGrouped, ...visitsGrouped }).map(date => ({
      date,
      restaurantClicks: clicksGrouped[date] || 0,
      restaurantShares: sharesGrouped[date] || 0,
      urlVisits: visitsGrouped[date] || 0,
    }));

    setCombinedData(combined);
  }, [restaurantClicks, restaurantShares, restaurantUrlVisits, timeFilter]);

  const handleFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  // Function to calculate total value
  const getTotal = (data) => {
    return data.reduce((sum, entry) => sum + entry.value, 0);
  };
  
  const total = getTotal(reservationTypesData);
  
  // Custom label function to show percentage
  const renderLabel = ({ name, value }) => {
    const percentage = ((value / total) * 100).toFixed(2);
    return `${value} (${percentage}%)`;
  };

  return (
    <>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{
          mt: 2,
          mb: 4,
          color: "linear-gradient(45deg, #1F487E, #333333)",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <BarChartIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        <Box component="span">
          Customer Engagement Analytics
        </Box>
      </Typography>

      {/* Top Cards */}
      <Grid container spacing={2} mb={6}>
        {['Restaurant Clicks', 'Restaurant Shares', 'URL Visits'].map((label, idx) => (
          <Grid item xs={12} md={3} key={label}>
            <Card 
              sx={{ 
                borderRadius: 2, 
                boxShadow: 4,
                bgcolor: "#fafafa", 
                border: `2px solid ${dataColors[idx]}`, 
                transition: "0.3s",
                '&:hover': {
                  transform: 'scale(1.03)',  // Scale up on hover
                  boxShadow: 8,  // Increase shadow on hover
                },
              }}
            >
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* Icon for Each Card */}
                {label === 'Restaurant Clicks' && <ThumbUp sx={{ color: dataColors[idx], fontSize: "3rem", bgcolor: "#fff", borderRadius: "50%", padding: "0.5rem", boxShadow: 1 }} />}
                {label === 'Restaurant Shares' && <Share sx={{ color: dataColors[idx], fontSize: "3rem", bgcolor: "#fff", borderRadius: "50%", padding: "0.5rem", boxShadow: 1 }} />}
                {label === 'URL Visits' && <Visibility sx={{ color: dataColors[idx], fontSize: "3rem", bgcolor: "#fff", borderRadius: "50%", padding: "0.5rem", boxShadow: 1 }} />}

                <Box>
                  {/* Card Label */}
                  <Typography variant="h6" color={dataColors[idx]} fontWeight="bold" sx={{ mb: 1 }}>
                    {label}
                  </Typography>
                  {/* Dynamic Data */}
                  <Typography variant="h4" color="#333" fontWeight="bold">
                    {label === 'Restaurant Clicks' ? restaurantClicks.length :
                      label === 'Restaurant Shares' ? restaurantShares.length :
                        restaurantUrlVisits.length}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Filter Section */}
      <FormControl sx={{ minWidth: 200, mb: 3 }}>
        <InputLabel>Filter By</InputLabel>
        <Select value={timeFilter} label="Filter By" onChange={handleFilterChange}>
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
        </Select>
      </FormControl>

      {/* Analytics Content */}
      {/* Combined Bar Chart */}
      <Card sx={{ mb: 4, borderRadius: 2, boxShadow: 2, bgcolor: "#fff" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom fontWeight="bold" color="#333">
            Engagement Over Time
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={combinedData}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="restaurantClicks" fill={dataColors[0]} stackId="a" />
              <Bar dataKey="restaurantShares" fill={dataColors[1]} stackId="a" />
              <Bar dataKey="urlVisits" fill={dataColors[2]} stackId="a" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Grid container spacing={2}>
        {/* Left Chart: Conversion Rate */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: 2, boxShadow: 2, bgcolor: "#fafafa" }}>
            <CardContent>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h6" fontWeight="bold" color="#333">
                  Online Reservation Conversion Rate
                </Typography>
                {/* Info Button with Tooltip */}
                <MuiTooltip title="Conversion Rate = (Platform Reservations / Total Restaurant Engagements) * 100">
                  <IconButton aria-label="conversion rate formula" size="small">
                    <InfoOutlined sx={{ color: "#333", fontSize: "1.2rem" }} />
                  </IconButton>
                </MuiTooltip>
              </Box>
            
              {/* Display the Percentage Number */}
              <Box display="flex" justifyContent="center" alignItems="center" height={250}>
                <Typography variant="h3" color="#ed9c00" fontWeight="bold">
                  {parseFloat(reservationPercentageData).toFixed(2)}%
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Chart: Reservation Type Breakdown */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: 2, boxShadow: 2, bgcolor: "#fafafa" }}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" color="#333">
                Reservation Type Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie 
                    data={reservationTypesData} 
                    dataKey="value" 
                    nameKey="name" 
                    cx="50%" 
                    cy="50%" 
                    outerRadius={80} 
                    label={renderLabel}
                  >
                    {reservationTypesData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={totalDataColors[index % totalDataColors.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend verticalAlign="top" height={50} />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerEngagementAnalytics;
