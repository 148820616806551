import {  useState } from "react";
import Cookies from "js-cookie";
import { useAuth } from "./authContext";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useRestaurantPackageServices = () => {
  const [restaurantsPackageData, setRestaurantsPackageData] = useState("");
  const [restaurantPackageInfo, setRestaurantPackageInfo] = useState("");
  const accessToken = Cookies.get("token");
  const { user } = useAuth();

  const getRestaurantPackages = async (restaurantId) => {
   
    try {
      const response = await fetch(
        `${backendUri}/api/v0/public/restaurants-packages/${restaurantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setRestaurantsPackageData(responseData);
      
      }
    } catch (error) {
      console.error("Error fetching restaurants package:", error);
    }
  };

  const getRestaurantPackagesByAdmin = async (restaurantId) => {
    let getPackagesRoute = `${backendUri}/api/v0/public/restaurants-packages/${restaurantId}`
    const headers = {
      "Content-Type": "application/json",
    };
    if(user.role === "admin"){
      getPackagesRoute =  `${backendUri}/api/v0/admin/packages`
      headers.Authorization = `Bearer ${accessToken}`;
    }
    try {
      const response = await fetch(
        getPackagesRoute,
        {
          method: "GET",
          headers,
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setRestaurantsPackageData(responseData);
      
      }
    } catch (error) {
      console.error("Error fetching restaurants package:", error);
    }
  };

  //Get restaurant package by id
  const fetchRestaurantPackageInfo = async (restaurantPackageId) => {
    try {

      const response = await fetch(`${backendUri}/api/v0/public/packages/${restaurantPackageId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const restaurantPackageData = await response.json();
        setRestaurantPackageInfo(restaurantPackageData);
      }
    } catch (error) {
      console.error("Error fetching restaurant package information:", error);
    }
  };

  return {
    getRestaurantPackages,
    restaurantsPackageData,
    fetchRestaurantPackageInfo,
    restaurantPackageInfo,
    getRestaurantPackagesByAdmin, 
  };
};

export default useRestaurantPackageServices;
