import { useState, useEffect } from "react";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useCampaignService = () => {
  const [launchCampaignChecked, setIsLaunchCampaignChecked] = useState(false);
  const [launchCampaignComplete, setIsLaunchCampaignComplete] = useState(false);

  useEffect(() => {
    getLaunchCampaignDetails();
  }, []);

  const getLaunchCampaignDetails = async () => {
    try {
      const response = await fetch(`${backendUri}/api/v0/public/campaigns`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const campaignData = await response.json();
        setIsLaunchCampaignChecked(campaignData[0].launchCampaign || false);
        setIsLaunchCampaignComplete(campaignData[0].completeLaunchCampaign || false);
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };
  return { 
    getLaunchCampaignDetails,
    launchCampaignChecked,
    launchCampaignComplete
  };
};

export default useCampaignService;

