import React from 'react';
import { Helmet } from 'react-helmet';


const MetaTags = ({ title, description, image, url, restaurant }) => {
  return (
    <Helmet>
      <title>{title || "Konfem Reservation | Discover Restaurants"}</title>
      <meta name="description" content={description || "Explore top dining experiences"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* JSON-LD for structured data */}
      {restaurant && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": restaurant.restaurantName,
            "description": restaurant.description || "Top restaurant in town",
            "image": restaurant.restaurantLogo || image,
            "url": url,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": restaurant.addressLine1 || "",
              "addressRegion": restaurant.state || "",
              "addressLocality": restaurant.city || "",
              "addressCountry": restaurant.country || "MY",
              "postalCode": restaurant.zipCode || "",
            },
            "aggregateRating": restaurant.rating ? {
              "@type": "AggregateRating",
              "ratingValue": restaurant.rating,
            } : undefined,
          })}
        </script>
      )}
    </Helmet>
  ) 
};

export default MetaTags;