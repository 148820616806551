import * as Icons from "tabler-icons-react";


export const MyComponent = (userRole, featureAnalyticsAndCRM) => {
  let SideMenu = [];

  if (userRole === "superAdmin") {
    SideMenu = [
      {
        group: "",
        contents: [
          {
            name: "SuperAdmin Analytics",
            icon: <Icons.CalendarEvent />, // Replace with your actual icon component
            path: "/app/ourdashboard",
          },
          {
            name: "SuperAdmin Dashboard",
            icon: <Icons.CalendarEvent />, // Replace with your actual icon component
            path: "/app/superadmindashboard",
          },
          {
            name: "RestaurantAdmin Management",
            icon: <Icons.UserSearch />,
            path: "/app/admin",
          },
          {
            name: "Restaurant",
            icon: <Icons.ToolsKitchen2 />, // Replace with your actual icon component
            path: "/app/restaurant",
          },
        ],
      },
    ];
  } else if (userRole === "client") {
    SideMenu = [
      {
        group: "",
        contents: [
          {
            name: "Upcoming Reservations",
            icon: <Icons.CalendarEvent />, // Replace with your actual icon component
            path: "/app/dashboard",
          },
          {
            name: "Historical Reservations",
            icon: <Icons.History />, // Replace with your actual icon component
            path: "/app/ended",
          },
        ],
      },
    ];
  } else if (userRole === "admin") {
    SideMenu = [
      {
        group: "",
        contents: [
          {
            name: "Dashboard",
            icon: <Icons.CalendarEvent />,
            path: "/app/dashboard",
          },
          {
            name: "Menu",
            icon: <Icons.Menu2 />,
            path: "/app/menu",
          },
          {
            name: "Package",
            icon: <Icons.PaperBag />,
            path: "/app/package",
          },
          {
            name: "Profile",
            icon: <Icons.Book2 />,
            path: "/app/settings",
          },
          ...(featureAnalyticsAndCRM ? [
            {
              name: "Analytics",
              icon: <Icons.ChartLine />,
              path: "/app/analytics",
            },
            {
              name: "CRM",
              icon: <Icons.Users />,
              path: "/app/crm",
            },
          ] : []),
        ],
      },
    ];
  }
  return SideMenu;
};
