import { useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useRestaurantCRMService = () => {
  const [restaurantCRMData, setRestaurantCRMData] = useState("");
  const [restaurantCRMInfo, setRestaurantCRMInfo] = useState("");
  const accessToken = Cookies.get("token");

  const getRestaurantCRM = async () => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/crm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setRestaurantCRMData(responseData);
      }
    } catch (error) {
      console.error("Error fetching restaurants CRM:", error);
    }
  };
    
  const getRestaurantCRMByAdmin = async (restaurantId) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/crm/${restaurantId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setRestaurantCRMInfo(responseData);
      }
    } catch (error) {
      console.error("Error fetching restaurants CRM:", error);
    }
  };

  const updateCRM = async (crmId, remark) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/crm/${crmId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ remark }),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        setRestaurantCRMInfo(responseData);
      }
    } catch (error) {
      console.error("Error updating restaurants CRM:", error);
    }
  };

  const createCRM = async (crmData) => {
    const restaurantId = crmData.restaurantId;
    const clientId = crmData.selectedClientId;
    const remark = crmData.remark;

    try {
      fetch(`${backendUri}/api/v0/admin/crm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ restaurantId, clientId, remark }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.message, {
              containerId: "toast-container-admin",
            });
          } else {
            toast.success(res.message, {
              containerId: "toast-container-admin",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          console.error("Fetch API fail");
        });
    } catch (error) {
      console.error("Error creating restaurants CRM:", error);
    }
  }

  return { 
    getRestaurantCRM, 
    getRestaurantCRMByAdmin,
    updateCRM, 
    createCRM,
    restaurantCRMData, 
    restaurantCRMInfo 
  };
};

export default useRestaurantCRMService;